import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import GameProtocolSidePanel from 'components/GameProtocolSidePanel';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';

import PlayerDataBlock from './PlayerDataBlock';
import PlayerInfoBlock from './PlayerInfoBlock';
import PlayerMultitool from './PlayerMultitool';

import './index.css';

// import GameProtocolSidePanel from '../GameProtocolSidePanel';
const PlayerPageController = () => {
  const dispatch = useAppDispatch();
  const { setTopPlayerRole } = teamPlayerReducer.actions;
  useEffect(() => {
    dispatch(setTopPlayerRole(null));
  }, []);
  const [playerProfileMode, setPlayerProfileMode] = useState('Overview');
  const [t] = useTranslation();
  return (
    <div className="player-page-controller">
      <PlayerInfoBlock
        playerProfileMode={playerProfileMode}
        setPlayerProfileMode={setPlayerProfileMode}
      />
      {playerProfileMode === t('Overview') ? (
        <PlayerDataBlock />
      ) : (
        <PlayerMultitool />
      )}
      <GameProtocolSidePanel />
    </div>
  );
};
export default PlayerPageController;
