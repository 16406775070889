import React, { useEffect, useState } from 'react';

import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';

import { AC_setIntermediateActivitiesAction } from '../../../actions/filter.actions';
import { EventsAPI } from '../../../api/events';
import { ReactComponent as FieldIconBlue } from '../../../assets/img/FieldIconBlue.svg';
import { ReactComponent as ChevronRight } from '../../../assets/img/icons/faChevronRight.svg';
import { ReactComponent as PlayCircle } from '../../../assets/img/icons/faPlayCircle.svg';
import { ReactComponent as PlayCircleFilled } from '../../../assets/img/icons/faPlayCircleFilled.svg';
import { ReactComponent as PlayCircleFilledDisabled } from '../../../assets/img/icons/faPlayCircleFilledDisabled.svg';
import { useAppDispatch } from '../../../store';

const MatchesBlock = () => {
  const [t] = useTranslation();
  const { setStatsCustomGameList, setExpandedGamesTeam } = teamReducer.actions;
  const { teamId } = useParams();
  const [matches, setMatches] = useState<any>([]);
  const { expandedGameClickedId, expandedGames } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const toggleExpanded = (matchId: string) => {
    if (expandedGames.includes(matchId)) {
      dispatch(
        setExpandedGamesTeam(expandedGames.filter((el) => el !== matchId)),
      );
    } else {
      dispatch(setExpandedGamesTeam([...expandedGames, matchId]));
    }
  };
  useEffect(() => {
    if (expandedGameClickedId !== null) {
      const gameDiv = document.getElementById(expandedGameClickedId.id);
      if (gameDiv) {
        gameDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
      if (expandedGameClickedId.expanded) {
        dispatch(setExpandedGamesTeam([expandedGameClickedId.id]));
      }
    }
  }, [matches]);
  const dispatch = useAppDispatch();
  const openProtocol = () => {
    // console.log('open protocol');
    // dispatch(openGameProtocol('123'));
  };

  useEffect(() => {
    if (teamId) {
      setMatches([]);
      EventsAPI.getGameStatsBySeason(null, teamId).then(
        (matchesStatsResponse) => {
          if (matchesStatsResponse.data) {
            for (const game of matchesStatsResponse.data) {
              for (const team of game.teams) {
                const opponentAutoGoals =
                  team.stats.filter((stat: any) => stat.key === 'owngoals')[0]
                    ?.value || 0;
                team.stats.map((stat: any) => {
                  if (stat.key === 'goal') {
                    stat.value += opponentAutoGoals;
                    stat.key = 'goal&m=owngoal';
                  }
                });
              }
            }
            setMatches(matchesStatsResponse.data);
          }
        },
      );
    }
  }, [teamId]);
  const { setExpandedGameClickedId } = teamReducer.actions;
  const makeLink = (match: any, team?: any, stat?: any, part?: any) => {
    let link = `/player?&eventId=${match.id}`;
    if (team) {
      if (part === 'highlights') {
        link = `${link}&teamId=${team?.id}`;
      } else {
        if (['ground_duel', 'aerial_duel'].includes(stat?.key)) {
          link = `${link}&teamId=${team?.id}`;
        } else {
          link = `${link}&teamId=${team?.id}&tid=${team.id}`;
        }
      }
    }
    if (stat) {
      link = `${link}&mid=${stat.metric_id}&m=${stat?.key}`;
    }
    if (part) {
      link = `${link}&p=${part}`;
    }

    dispatch(AC_setIntermediateActivitiesAction([match.id]));
    dispatch(setStatsCustomGameList([match.id]));
    return link;
  };
  return (
    <div className="block-wrapper left">
      <div className="block-title">
        <div>{t('Matches')}</div>
      </div>
      <div className="block-content flex-column">
        {matches.map((match: any) => (
          <div
            className={`team-match-wrapper ${expandedGames.includes(match.id) ? 'pressed' : 'enabledClickable'}`}
            onClick={() => {
              if (!expandedGames.includes(match.id)) {
                toggleExpanded(match.id);
              }
            }}
            id={match.id}
            key={match.id}
          >
            <div
              className="enabledClickable"
              onClick={() => toggleExpanded(match.id)}
            >
              <div
                style={{
                  width: 32,
                  height: 32,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                }}
              >
                <ChevronRight
                  className={`${expandedGames.includes(match.id) ? 'rotate90' : 'rotate0'} `}
                  fill="var(--colorLink, #1677FF)"
                />
              </div>
              <div className="match-details">
                <div>{match.date}</div>
                <div className="elipsis-text">{match.tournament?.country}</div>
                <div style={{ textWrap: 'wrap' }}>{match.tournament?.name}</div>
              </div>
              <div className="match-participants-container">
                <div style={{ textAlign: 'end', flex: 1, textWrap: 'nowrap' }}>
                  {match.teams[0].name}
                </div>
                <div
                  className="score"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div>{match.teams[0].score}</div>:
                  <div>{match.teams[1].score}</div>
                </div>
                <div
                  style={{ textAlign: 'start', flex: 1, textWrap: 'nowrap' }}
                >
                  {match.teams[1].name}
                </div>
              </div>
              <div className="enabledClickable">
                <FieldIconBlue
                  onClick={(e) => {
                    e.stopPropagation();
                    openProtocol();
                  }}
                />
              </div>
              <Divider type="vertical" style={{ height: 80 }} />
              <div
                className="buttons-controller"
                style={{ alignContent: 'center' }}
              >
                <div
                  className="enabledClickable"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setExpandedGameClickedId({
                        id: match.id,
                        expanded: expandedGames.includes(match.id),
                      }),
                    );
                    const link = makeLink(match, { id: teamId }, null, 'full');
                    window.open(link, '_blank');
                  }}
                >
                  <PlayCircleFilled />
                  {t('Match')}
                </div>
                <div className="buttons-controller-disabled">
                  <PlayCircleFilledDisabled />
                  {t('Ball in game')}
                </div>
                <div
                  className="enabledClickable"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setExpandedGameClickedId({
                        id: match.id,
                        expanded: expandedGames.includes(match.id),
                      }),
                    );
                    const link = makeLink(
                      match,
                      { id: teamId },
                      null,
                      'highlights',
                    );
                    window.open(link, '_blank');
                  }}
                >
                  <PlayCircleFilled />
                  {t('Highlights')}
                </div>
              </div>
            </div>
            {expandedGames.includes(match.id) && (
              <div className="flex-row f-ga-8">
                {match.teams.map((team: any, indexTeams: number) => (
                  <div className="match-skills-container" key={indexTeams}>
                    <div className="match-skills-details">
                      <div className="team-row">
                        <div className="team-name">{team.name}</div>
                        <div style={{ flex: 1 }} className="match-stats-block">
                          {team.stats
                            .filter((stat: any) => stat.show_in_game_stats)
                            .map((stat: any, indexStats: number) => (
                              <div
                                key={indexStats}
                                className="flex-column f-ga-4"
                                style={{
                                  minWidth: 99,
                                  height: 'auto',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <div className="stat-header">
                                  {t(stat.name)}
                                </div>
                                <div
                                  className={`  ${stat.value === null ? 'na-stat' : stat.value > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
                                  style={{ minWidth: 70 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (stat.value) {
                                      dispatch(
                                        setExpandedGameClickedId({
                                          id: match.id,
                                          expanded: expandedGames.includes(
                                            match.id,
                                          ),
                                        }),
                                      );
                                      const link = makeLink(match, team, stat);
                                      window.open(link, '_blank');
                                    }
                                  }}
                                >
                                  {stat.value ? (
                                    <PlayCircle height={32} />
                                  ) : (
                                    <></>
                                  )}
                                  <div
                                    style={{ flex: 1, height: 32 }}
                                    className={`${stat.value === null ? 'flex-row j-ce ai-c disabled-stat-color' : 'flex-row ai-c'}`}
                                  >
                                    {stat.value === null
                                      ? t('n/a')
                                      : stat.value}
                                  </div>
                                  <div className="stat-average">
                                    {stat.average !== undefined
                                      ? `${stat.average}%`
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default MatchesBlock;
