/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';

import { Checkbox, ConfigProvider, Dropdown, Flex, Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AC_OpenFilterAction } from 'actions/filter.actions';
import {
  AC_SetDownloadEpisodesAction,
  AC_SetIsPlayingVideoAction,
  AC_SetPlayerHotkeysAllowedAction,
  // AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { getRootPlaylistsActionV2 } from 'actions/playlistV2.async.actions';
import { ReactComponent as ArrowLeft } from 'assets/img/icons/faArrowLeft.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/faDownloadIcon.svg';
// import { ReactComponent as FilterIconLight } from 'assets/img/icons/faFilterLight.svg';
// import { ReactComponent as FolderOpen } from 'assets/img/icons/faFolderOpen.svg';
import { ReactComponent as PlusIcon } from 'assets/img/icons/faPlus.svg';
import { ReactComponent as ShareIcon } from 'assets/img/icons/faShareIcon.svg';
import { ReactComponent as StarWithPlusLight } from 'assets/img/icons/faStarWithPlusLight.svg';
// import ReelsLengthWarning from 'components/ReelsLengthWarning';
import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';
import { useAppDispatch } from 'store';
import {
  NEW_PLAYLIST_TEXT,
  REELS_DURATION_WARNING_THRESHOLD_MS,
  WINDOW_MODE_WIDTH,
} from 'types/constants';
import { filterAndIndex } from 'types/functions';
import { EpisodeFilterPanelTypes, MenuItemType } from 'types/types';
import { getItem } from 'utils/functions';

import { EventsAPI } from '../../../api/events';
import { PlaylistsAPI } from '../../../api/playlists';
import { NEW_FOLDER } from '../../../types/constants';
import DownloadEpisodesContext from '../../contexts/DownloadEpisodesContext';
// import EpisodesNavbarContext from '../../contexts/EpisodesNavbarContext';
import PlaylistSortingContext from '../../contexts/PlaylistSortingContext';
import PlaylistViewContext from '../../contexts/PlaylistViewContext';
// import Loader from '../../Loader';

const TopBar: FC<any> = ({
  returnToFoldersCallback,
  isShared,
  setShowPlaylistsSidebar,
  constructDowloadLinkRequest,
  resultConfirmationModalHandle,
  episodesController,
  getTotalEpisodesCount,
  sharePlaylistCallable,
  movePlaylistToFolderCallback,
  setRenameCurrentPlaylist,
  setAddNewFolder,
  addNewFolder,
  setAddNewPlaylist,
  addNewPlaylist,
  inputRefPlaylist,
  inputRef,
}: {
  returnToFoldersCallback: any;
  isShared: any;
  setShowPlaylistsSidebar: any;
  constructDowloadLinkRequest: any;
  resultConfirmationModalHandle: any;
  episodesController: any;
  getTotalEpisodesCount: any;
  sharePlaylistCallable: any;
  movePlaylistToFolderCallback: any;
  setRenameCurrentPlaylist: any;
  setAddNewFolder: any;
  addNewFolder: any;
  setAddNewPlaylist: any;
  addNewPlaylist: any;
  inputRefPlaylist: any;
  inputRef: any;
}) => {
  const textRef = React.useRef<any>(null);
  const playlistNameRef = React.useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { openedPlaylist, playlistIsLoading, sharedPlaylist } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  const { setPlaylistForDeletionId } = playlistReducerV2.actions;
  const { filteredVideos, playerMode } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { activeFilter } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  const { selectedReelsDurationWarning } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { resetReelsState, setSelectedReelsDurationWarning } =
    reelsReducer.actions;
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const isOverflowing =
    (playlistNameRef?.current?.scrollWidth || 0) >
    (textRef?.current?.clientWidth || 0);
  const NAVBAR_TITLE: any = {
    episodes: t('Episodes'),
    playlists: t('Playlists'),
    playlist_view: openedPlaylist?.name || sharedPlaylist?.name,
  };

  const [openedMenu, setOpenedMenu] = React.useState<string>(
    activeFilter ? 'filters' : '',
  );
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const { AC_setOpenedPlaylist } = playlistReducerV2.actions;
  useEffect(() => {
    if (activeFilter && activeFilter !== '') {
      setOpenedMenu('filters');
    }
  }, [activeFilter]);
  const openFilter = (filterName: EpisodeFilterPanelTypes | string) => {
    if (activeFilter) {
      dispatch(AC_OpenFilterAction(''));
    } else {
      dispatch(AC_OpenFilterAction(filterName));
    }
  };
  const [isChecked, setIsChecked] = React.useState(() => {
    if (playerMode === 'episode') {
      return filteredVideos.length === episodesController.selectedEpisodes;
    } else if (playerMode === 'playlist_view') {
      return episodesController.selectedEpisodes === getTotalEpisodesCount();
    }
  });
  const [indeterminate, setIndeterminate] = React.useState(false);

  const deleteSelectedFromPlaylist = async () => {
    if (episodesController.selectedEpisodes) {
      let finalPlaylist: any = { ...openedPlaylist };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [index, selVideo] of Object.keys(
        episodesController.selectedEpisodes,
      ).entries()) {
        for (const episodeId of episodesController.selectedEpisodes[selVideo]) {
          await PlaylistsAPI.removePlaylistElement(
            finalPlaylist.id,
            episodeId,
          ).then(() => {
            const game = filterAndIndex(
              finalPlaylist.playlist_events,
              selVideo.slice(0, -2),
              'id',
            );
            const deletedEpisode = filterAndIndex(
              game.element.episodes,
              episodeId,
              'id',
            );
            const finalEpisodes = [
              ...game.element.episodes.slice(0, deletedEpisode.index),
              ...game.element.episodes.slice(deletedEpisode.index + 1),
            ];
            if (finalEpisodes.length > 0) {
              finalPlaylist = {
                ...finalPlaylist,
                playlist_events: [
                  ...finalPlaylist.playlist_events.slice(0, game.index),
                  {
                    ...game.element,
                    episodes: [
                      ...game.element.episodes.slice(0, deletedEpisode.index),
                      ...game.element.episodes.slice(deletedEpisode.index + 1),
                    ],
                  },
                  ...finalPlaylist.playlist_events.slice(game.index + 1),
                ],
              };
            } else {
              finalPlaylist = {
                ...finalPlaylist,
                playlist_events: [
                  ...finalPlaylist.playlist_events.slice(0, game.index),
                  ...finalPlaylist.playlist_events.slice(game.index + 1),
                ],
              };
            }
          });
        }
      }
      dispatch(AC_setOpenedPlaylist(finalPlaylist));
    }
  };
  const { selected } = useSelector(
    (state: AppStateType) => state.videosListReducer,
  );
  useEffect(() => {
    let allSelectedGames = 0;
    let selectedGames = 0;
    let reelsDuration = 0;
    if (selected) {
      for (const gameId of Object.keys(selected)) {
        if (selected[gameId].allSelected) {
          allSelectedGames += 1;
        } else {
          selectedGames += 1;
        }
      }
      if (playerMode === 'episodes') {
        if (filteredVideos.length === allSelectedGames) {
          setIndeterminate(false);
          setIsChecked(true);
        } else if (
          filteredVideos.length > allSelectedGames &&
          (allSelectedGames > 0 || selectedGames > 0)
        ) {
          setIndeterminate(true);
          setIsChecked(true);
        } else if (
          allSelectedGames === 0 &&
          selectedGames === 0 &&
          filteredVideos.length > 0
        ) {
          setIndeterminate(false);
          setIsChecked(false);
        }
      } else if (playerMode === 'playlist_view' && openedPlaylist) {
        if (openedPlaylist.playlist_events.length === allSelectedGames) {
          setIndeterminate(false);
          setIsChecked(true);
        } else if (
          openedPlaylist.playlist_events.length > allSelectedGames &&
          (allSelectedGames > 0 || selectedGames > 0)
        ) {
          setIndeterminate(true);
          setIsChecked(true);
        } else if (
          allSelectedGames === 0 &&
          selectedGames === 0 &&
          filteredVideos.length > 0
        ) {
          setIndeterminate(false);
          setIsChecked(false);
        }
      }
      Object.keys(selected).forEach((key: string) => {
        let base;
        const originalKey = key;
        if (playerMode === 'playlist_view' && openedPlaylist) {
          base = openedPlaylist.playlist_events;
        } else if (playerMode === 'episodes' && filteredVideos.length > 0) {
          key = key.slice(0, -2);
          base = filteredVideos;
        }
        if (base) {
          const video = base.filter((event) => event.id === key)[0];
          const episodes = video?.episodes?.filter(
            (episode: any) =>
              selected[originalKey].episodes.includes(episode.id) &&
              episode.keyframes?.length &&
              episode.keyframes?.length > 0,
          );
          episodes?.forEach((episode: any) => {
            if (episode.user_timer) {
              reelsDuration +=
                episode.user_timer.finished_at - episode.user_timer.started_at;
            } else {
              reelsDuration +=
                episode.file_timer.finished_at -
                episode.file_timer.started_at +
                WINDOW_MODE_WIDTH * 2 * 1000;
            }
          });
        }
      });
      if (reelsDuration > REELS_DURATION_WARNING_THRESHOLD_MS) {
        dispatch(setSelectedReelsDurationWarning(true));
      } else if (selectedReelsDurationWarning) {
        dispatch(setSelectedReelsDurationWarning(false));
      }
    }
  }, [selected, openedPlaylist, filteredVideos]);
  const shareSelectedEpisodes = async () => {
    if (openedPlaylist) {
      sharePlaylistCallable(openedPlaylist.id);
      return;
    }
    let allEpisodes: any[] = [];
    for (const game of Object.keys(selected)) {
      allEpisodes = [...allEpisodes, ...selected[game].episodes];
    }
    const language = localStorage.getItem('i18nextLng') || 'en';
    const response = await PlaylistsAPI.getSuggestedPlaylistName(language);
    const name = response.data.name;
    const newPlaylistResponse = await PlaylistsAPI.addPlaylist(name || '');
    const createdUserEpisodes: string[] = [];
    let processedEpisodeIds: string[] = [...allEpisodes];
    const process = async (
      ep: any,
      processedEpisodeIds: string[],
    ): Promise<string[]> => {
      const newUserEpisode: any = {
        video: ep.video.id,
        file_timer: {
          started_at:
            ep.user_timer?.started_at !== null &&
            ep.user_timer?.started_at !== undefined
              ? ep.user_timer?.started_at
              : ep.file_timer?.started_at,
          finished_at: ep.user_timer?.finished_at || ep.file_timer?.finished_at,
        },
        game_timer: {
          started_at: ep.game_timer?.started_at,
          finished_at: ep.game_timer?.finished_at,
        },
        event_name: ep.user_event_name || ep.event_name || ep.event_type.name,
      };
      const newEpisodeResponse =
        await EventsAPI.addNewUserEpisode(newUserEpisode);
      createdUserEpisodes.push(newEpisodeResponse.data.id);
      processedEpisodeIds = processedEpisodeIds.filter((el) => el !== ep.id);
      return processedEpisodeIds;
    };
    for (let vidId = 0; vidId < filteredVideos.length; vidId++) {
      const vid = filteredVideos[vidId];
      const user_eps: any[] | undefined = vid?.user_episodes?.filter(
        (el: any) => allEpisodes.includes(el.id) && el.isTemporary,
      );

      if (user_eps) {
        for (let i = 0; i < user_eps.length; i++) {
          const ep = user_eps[i];
          processedEpisodeIds = await process(ep, processedEpisodeIds);
        }
      }
      const changed_eps: any[] | undefined = vid?.episodes?.filter(
        (el: any) => allEpisodes.includes(el.id) && el.isTemporary,
      );
      if (changed_eps) {
        for (let i = 0; i < changed_eps.length; i++) {
          const ep = changed_eps[i];
          processedEpisodeIds = await process(ep, processedEpisodeIds);
        }
      }
    }
    const finalEpisodesIdsList = [
      ...processedEpisodeIds,
      ...createdUserEpisodes,
    ];
    const newCreatedPlaylist = newPlaylistResponse.data;
    // alert();
    await PlaylistsAPI.addToPlaylists(
      finalEpisodesIdsList,
      [newCreatedPlaylist.id],
      true,
    );
    sharePlaylistCallable(newCreatedPlaylist.id);
  };
  useEffect(() => {
    if (addNewPlaylist && inputRefPlaylist.current) {
      //@ts-ignore
      inputRefPlaylist.current!.focus({
        cursor: 'all',
      });
    }
  }, [addNewPlaylist]);
  const { AC_initialzeSelectedEpisodes } = videosListReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setCreateEpisodeMode } = playerReducerV2.actions;

  const toggleSelectAll = () => {
    if (indeterminate || (!indeterminate && !isChecked)) {
      const allVideos: any = {};
      if (playerMode === 'episodes') {
        for (const [elementIndex, videoElement] of filteredVideos.entries()) {
          allVideos[`${videoElement.id}-${elementIndex}`] = {
            allSelected: true,
            episodes: videoElement.episodes.map((episode: any) => episode.id),
          };
        }
      } else if (playerMode === 'playlist_view' && openedPlaylist) {
        for (const [
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          elementIndex,
          videoElement,
          // eslint-disable-next-line no-unsafe-optional-chaining
        ] of openedPlaylist?.playlist_events?.entries()) {
          allVideos[`${videoElement?.id}`] = {
            allSelected: true,
            episodes: videoElement.episodes.map((episode: any) => episode.id),
          };
        }
      }
      dispatch(AC_initialzeSelectedEpisodes(allVideos));
    } else if (isChecked) {
      dispatch(AC_initialzeSelectedEpisodes({}));
    }
  };
  useEffect(() => {
    if (addNewFolder && inputRef.current) {
      //@ts-ignore
      inputRef.current!.focus({
        cursor: 'all',
      });
    }
  }, [addNewFolder]);
  const { setEpisodeIdsToAddToPlaylist } = videosListReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addSelectedEpisodesToPlaylist = () => {
    let allEpisodes: any[] = [];
    for (const game of Object.keys(selected)) {
      allEpisodes = [...allEpisodes, ...selected[game].episodes];
    }
    dispatch(setEpisodeIdsToAddToPlaylist({ episodeIds: allEpisodes }));
    dispatch(AC_SetIsPlayingVideoAction(false));
    dispatch(AC_SetPlayerHotkeysAllowedAction(false));
  };

  const items = [
    {
      key: 1,
      label: (
        <div
          onClick={() => {
            setAddNewFolder(!addNewFolder);
            setAddNewPlaylist(false);
            dispatch(AC_SetPlayerHotkeysAllowedAction(false));
          }}
        >
          {t(NEW_FOLDER)}
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          onClick={() => {
            setAddNewFolder(false);
            dispatch(AC_SetPlayerHotkeysAllowedAction(false));
            setAddNewPlaylist(!addNewPlaylist);
          }}
        >
          {t(NEW_PLAYLIST_TEXT)}
        </div>
      ),
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { resetEditMode } = playerReducerV2.actions;
  const [newElementContentOpen, setNewElementContextOpen] = useState(false);
  const topMenuItemsEpisodes: MenuItemType[] = [
    getItem(
      'download',
      'download',
      currentUser?.custom_permissions.includes('can_cut_reels') ? (
        <DownloadEpisodesContext
          playlist={{}}
          constructDowloadLinkRequest={constructDowloadLinkRequest}
          resultConfirmationModalHandle={resultConfirmationModalHandle}
          isDisabled={!(isChecked || indeterminate)}
        />
      ) : (
        <ConfigProvider
          theme={{
            token: {
              colorBgElevated: 'var(--colorBgSpotlight)',
              colorText: 'var(--colorText)',
            },
          }}
        >
          <Tooltip title={t('Download')}>
            <DownloadIcon
              style={{
                display: 'block',
              }}
              className={isChecked || indeterminate ? 'activeButtonIcon' : ''}
            />
          </Tooltip>
        </ConfigProvider>
      ),
      () => {
        if (!(isChecked || indeterminate)) {
          return;
        }
        if (!currentUser?.custom_permissions.includes('can_cut_reels')) {
          let simpleCount = 0;
          if (openedPlaylist) {
            Object.keys(selected).forEach((instanceId: string) => {
              const playlist = openedPlaylist.playlist_events.filter(
                (event) => event.id === instanceId,
              )[0];
              if (playlist) {
                playlist.episodes.forEach((episode) => {
                  if (selected[instanceId].episodes.includes(episode.id)) {
                    simpleCount += 1;
                  }
                });
              }
            });
          } else {
            if (filteredVideos.length > 0) {
              Object.keys(selected).forEach((instanceId: string) => {
                const video = filteredVideos.filter(
                  (video) => video.id === instanceId.slice(0, -2),
                )[0];
                if (video) {
                  video.episodes.forEach((episode: any) => {
                    if (selected[instanceId].episodes.includes(episode.id)) {
                      simpleCount += 1;
                    }
                  });
                  video.user_episodes?.forEach((episode: any) => {
                    if (selected[instanceId].episodes.includes(episode.id)) {
                      simpleCount += 1;
                    }
                  });
                }
              });
            }
          }
          if (Number(simpleCount) > 1) {
            dispatch(AC_SetDownloadEpisodesAction('manual'));
          } else {
            constructDowloadLinkRequest(true, false, false, false);
            resultConfirmationModalHandle(true);
          }
          openFilter('');
          setAddNewFolder(false);
          dispatch(AC_SetPlayerHotkeysAllowedAction(true));
          setAddNewPlaylist(false);
          if (openedMenu === 'download') {
            setOpenedMenu('');
          } else {
            setOpenedMenu('download');
          }
        }
      },
      'icon',
      null,
      {
        width: 40,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    ),
    getItem(
      'share',
      'share',
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgSpotlight)',
            colorText: 'var(--colorText)',
          },
        }}
      >
        <Tooltip title={t('Share')}>
          <Flex
            className={isChecked || indeterminate ? 'activeButtonIcon' : ''}
            style={{
              width: 32,
            }}
            align="center"
            justify="center"
          >
            <ShareIcon
              style={{
                display: 'block',
                margin: '4 2 4 2',
              }}
            />
          </Flex>
        </Tooltip>
      </ConfigProvider>,
      () => {
        (isChecked || indeterminate) && shareSelectedEpisodes();
      },
      'icon',
      null,
      {
        width: 40,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    ),
    // getItem(
    //   'folders',
    //   'folders',
    //   <Tooltip title={t('Add to folder')}>
    //     <Flex
    //       className="activeButtonIcon"
    //       style={{
    //         width: '32px',
    //       }}
    //       align="center"
    //       justify="center"
    //     >
    //       <StarWithPlusLight style={{ display: 'block', margin: '4 2 4 2' }} />
    //     </Flex>
    //   </Tooltip>,
    //   () => {
    //     openFilter('');
    //     setAddNewFolder(false);
    //     setShowPlaylistsSidebar((prev: boolean) => !prev);
    //     dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    //     if (openedMenu === 'folders') {
    //       setOpenedMenu('');
    //     } else {
    //       setOpenedMenu('folders');
    //     }
    //   },
    //   'icon',
    //   null,
    //   {
    //     width: 40,
    //     height: 32,
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //   },
    // ),
  ];
  const topMenuItemsPlaylists: MenuItemType[] = [
    getItem(
      'addFolder',
      'addFolder',
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgSpotlight)',

            colorText: 'var(--colorText)',
          },
        }}
      >
        <Dropdown
          menu={{ items }}
          overlayStyle={{ width: 'max-content' }}
          placement="bottomRight"
          onOpenChange={() =>
            setNewElementContextOpen((prev: boolean) => !prev)
          }
        >
          <Flex
            style={{ width: 32, height: 32 }}
            className={newElementContentOpen ? 'activeButton' : ''}
            align="center"
            justify="center"
          >
            <PlusIcon
              style={{
                display: 'block',
                // margin: '4 2 4 2',
              }}
            />
          </Flex>
        </Dropdown>
      </ConfigProvider>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      'icon',
    ),
    getItem(
      'reorder',
      'reorder',
      <PlaylistSortingContext
        playlist={openedPlaylist}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        sortingToggleCallback={(sorting: string) =>
          dispatch(getRootPlaylistsActionV2(sorting))
        }
      />,
      () => {
        openFilter('');
        dispatch(AC_SetPlayerHotkeysAllowedAction(true));
        setAddNewFolder(false);
      },
      'icon',
    ),
  ];
  const topMenuItemsPlaylistViewMode: MenuItemType[] = [
    getItem(
      'download',
      'download',
      currentUser?.custom_permissions.includes('can_cut_reels') ? (
        <DownloadEpisodesContext
          playlist={{}}
          constructDowloadLinkRequest={constructDowloadLinkRequest}
          resultConfirmationModalHandle={resultConfirmationModalHandle}
          isDisabled={!(isChecked || indeterminate)}
        />
      ) : (
        <ConfigProvider
          theme={{
            token: {
              colorBgElevated: 'var(--colorBgSpotlight)',
              colorText: 'var(--colorText)',
            },
          }}
        >
          <DownloadIcon style={{ display: 'block' }} />
        </ConfigProvider>
      ),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {
        if (!currentUser?.custom_permissions.includes('can_cut_reels')) {
          let simpleCount = 0;
          if (openedPlaylist) {
            Object.keys(selected).forEach((instanceId: string) => {
              const playlist = openedPlaylist.playlist_events.filter(
                (event) => event.id === instanceId,
              )[0];
              if (playlist) {
                playlist.episodes.forEach((episode) => {
                  if (selected[instanceId].episodes.includes(episode.id)) {
                    simpleCount += 1;
                  }
                });
              }
            });
          } else {
            if (filteredVideos.length > 0) {
              Object.keys(selected).forEach((instanceId: string) => {
                const video = filteredVideos.filter(
                  (video) => video.id === instanceId.slice(0, -2),
                )[0];
                if (video) {
                  video.episodes.forEach((episode: any) => {
                    if (selected[instanceId].episodes.includes(episode.id)) {
                      simpleCount += 1;
                    }
                  });
                  video.user_episodes?.forEach((episode: any) => {
                    if (selected[instanceId].episodes.includes(episode.id)) {
                      simpleCount += 1;
                    }
                  });
                }
              });
            }
          }
          if (Number(simpleCount) > 1) {
            dispatch(AC_SetDownloadEpisodesAction('manual'));
          } else {
            constructDowloadLinkRequest(true, false, false, false);
            resultConfirmationModalHandle(true);
          }
        }
      },
      'icon',
    ),
    getItem(
      'openFolders',
      'openFolders',
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgSpotlight)',
            colorText: 'var(--colorText)',
          },
        }}
      >
        <Tooltip title={t('Share')}>
          <Flex
            className={isChecked || indeterminate ? 'activeButtonIcon' : ''}
            style={{
              width: 32,
            }}
            align="center"
            justify="center"
          >
            <ShareIcon style={{ display: 'block', margin: '4 2 4 2' }} />
          </Flex>
        </Tooltip>
      </ConfigProvider>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {
        (isChecked || indeterminate) && shareSelectedEpisodes();
      },
      'icon',
    ),
    getItem(
      'context',
      'context',
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgSpotlight)',
            colorText: 'var(--colorText)',
            // lineHeight: 12,
          },
        }}
      >
        <PlaylistViewContext
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          reorderByTimeCallback={() => {}}
          shareCallback={sharePlaylistCallable}
          moveToFolderCallback={movePlaylistToFolderCallback}
          renameCallback={() => {
            dispatch(AC_SetPlayerHotkeysAllowedAction(false));
            setRenameCurrentPlaylist(true);
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          deleteCallback={(id: string) =>
            dispatch(setPlaylistForDeletionId(id))
          }
          deleteSelectedFromPlaylist={deleteSelectedFromPlaylist}
          playlist={openedPlaylist}
          hasSelected={
            Object.keys(episodesController.selectedEpisodes)?.length > 0
          }
        />
      </ConfigProvider>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      'icon',
      null,
      { height: '24px', display: 'flex', alignItems: 'center' },
    ),
    getItem(
      'selectAll',
      'selectAll',
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgSpotlight)',
            colorText: 'var(--colorText)',
          },
        }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: '#141414',
              colorBorder: '#424242',
              lineWidth: 1,
            },
          }}
        >
          <Checkbox
            style={{
              paddingLeft: '0px',
              width: 24,
              display: 'flex',
              justifyContent: 'center',
            }}
            checked={isChecked}
            indeterminate={indeterminate}
            onChange={toggleSelectAll}
            type="checkbox"
          />
        </ConfigProvider>
      </ConfigProvider>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      'icon',
      null,
      { height: '24px', display: 'flex', alignItems: 'center' },
    ),
  ];
  const gettopMenuItems = (mode: string) => {
    let items: any = [];
    if (mode === 'episodes') {
      items = topMenuItemsEpisodes;
    } else if (mode === 'playlist_view') {
      items = topMenuItemsPlaylistViewMode;
    }
    return items;
  };
  const NAVBAR_MENU_ITEMS: any = {
    episodes: gettopMenuItems('episodes'),
    playlists: topMenuItemsPlaylists,
    playlist_view: gettopMenuItems('playlist_view'),
  };
  if (playlistIsLoading) {
    return <div className="flex-row flex-1"> </div>;
  }
  return (
    <>
      <div className="flex-row f-ga-8 ai-c" ref={textRef}>
        {playerMode === 'playlist_view' && !sharedPlaylist && (
          <ArrowLeft
            className="enabledClickable"
            style={{ marginLeft: 8 }}
            onClick={() => returnToFoldersCallback()}
          />
        )}
        <Tooltip
          color="var(--tooltip-75)"
          overlayStyle={{
            border:
              '1px solid var(--character-disabled-amp-placeholder-25, rgba(255, 255, 255, 0.30))',
            borderRadius: '8px',
          }}
          title={isOverflowing ? NAVBAR_TITLE[playerMode] : ''}
          placement="bottom"
          arrow={false}
        >
          <div
            ref={playlistNameRef}
            className="elipsis-text"
            style={{
              maxWidth: isShared ? 330 : 136,
            }}
          >
            {NAVBAR_TITLE[playerMode]}
          </div>
        </Tooltip>
      </div>
      <div className="flex-row">
        {!sharedPlaylist && (
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  iconSize: 16,
                  collapsedIconSize: 16,
                  itemMarginInline: 4,
                  itemPaddingInline: 0,
                  itemMarginBlock: 10,
                  iconMarginInlineEnd: 0,
                  darkItemBg: 'transparent',
                  darkItemSelectedBg: 'var(--colorBgElevated)',
                  darkItemSelectedColor: 'transparent',
                  horizontalItemBorderRadius: 6,
                },
              },
              token: {
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Menu
              selectable={false}
              style={{
                height: '32px',
                borderRadius: 8,
                display: 'flex',
                flexDirection: 'row',
                borderBottom: 0,
                gap: 4,
                padding: '0px 0px 0px 0px',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
              mode="horizontal"
              theme={'dark'}
              selectedKeys={[openedMenu]}
              defaultSelectedKeys={['filters']}
              items={NAVBAR_MENU_ITEMS[playerMode]}
              itemType="icon"
              inlineCollapsed={true}
              disabledOverflow={true}
            />
            {playerMode === 'episodes' && (
              <div className="flex-row j-ce ai-c f-ga-4">
                <Checkbox
                  style={{
                    paddingLeft: '0px',
                    width: 32,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  checked={isChecked}
                  indeterminate={indeterminate}
                  onChange={toggleSelectAll}
                />
              </div>
            )}
          </ConfigProvider>
        )}
      </div>
    </>
  );
};
export default TopBar;
