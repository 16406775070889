import React, { useEffect, useState } from 'react';

import { ConfigProvider, Flex, Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

const PersonalInfo = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [years, setYears] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  const [t] = useTranslation();
  const { openedTeamPlayer, topPlayerRole } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const translateYears = () => {
    if (years === 0 || (years > 10 && years.toString().endsWith('1'))) {
      return t('year');
    }
    if ([10, 11, 12, 13, 14].includes(years)) {
      return t('years_many');
    }
    if (
      years.toString().endsWith('2') ||
      years.toString().endsWith('3') ||
      years.toString().endsWith('4')
    ) {
      return t('years_two');
    }
    return t('years_many');
  };
  useEffect(() => {
    if (openedTeamPlayer) {
      const y = dayjs().diff(dayjs(openedTeamPlayer.birthday), 'year');
      setYears(y);
    }
  }, [openedTeamPlayer]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Tag: {
            defaultColor: 'var(--blue-9, #002C8C)',
            defaultBg: 'var(--blue-1, #E6F4FF)',
            marginXS: 0,
            borderRadiusSM: 4,
          },
        },
        token: {
          fontSizeSM: 14,
        },
      }}
    >
      <Flex className="team-player-stats-container" gap={12}>
        <Flex className="team-player-stats-block">
          <Flex gap={17} vertical className="f-grow">
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Birthday')} </span>
              {openedTeamPlayer?.birthday ? (
                <Tag bordered={false}>
                  {dayjs(openedTeamPlayer?.birthday).format('DD.MM.YYYY')}
                </Tag>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Tag: {
                        defaultColor:
                          'var(--colorTextDescription, rgba(0, 0, 0, 0.45))',
                        defaultBg: 'var(--blue-1, #E6F4FF)',
                        marginXS: 0,
                      },
                    },
                    token: {
                      fontSizeSM: 14,
                    },
                  }}
                >
                  <Tag bordered={false}>{t('no data')}</Tag>
                </ConfigProvider>
              )}
            </Flex>
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Age')} </span>
              {years ? (
                <Tag bordered={false}>{`${years} ${translateYears()}`}</Tag>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Tag: {
                        defaultColor:
                          'var(--colorTextDescription, rgba(0, 0, 0, 0.45))',
                        defaultBg: 'var(--blue-1, #E6F4FF)',
                        marginXS: 0,
                      },
                    },
                    token: {
                      fontSizeSM: 14,
                    },
                  }}
                >
                  <Tag bordered={false}>{t('no data')}</Tag>
                </ConfigProvider>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex className="team-player-stats-block">
          <Flex gap={17} vertical className="f-grow">
            <Flex justify="space-between" align="center" gap={4}>
              <span className="span-label">{t('Position')}</span>
              {topPlayerRole ? (
                <Flex className="capitalize" style={{ fontSize: 16 }} gap={2}>
                  {topPlayerRole.map((role: string, index: number) => (
                    <Tag key={index} bordered={false}>
                      {t(role)}
                    </Tag>
                  ))}
                </Flex>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Tag: {
                        defaultColor:
                          'var(--colorTextDescription, rgba(0, 0, 0, 0.45))',
                        defaultBg: 'var(--blue-1, #E6F4FF)',
                        marginXS: 0,
                      },
                    },
                    token: {
                      fontSizeSM: 14,
                    },
                  }}
                >
                  <Tag bordered={false}>{t('no data')}</Tag>
                </ConfigProvider>
              )}
            </Flex>
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Leg')} </span>
              {openedTeamPlayer?.main_leg &&
              openedTeamPlayer.main_leg !== 'unset' ? (
                <span className="capitalize" style={{ fontSize: 16 }}>
                  <Tag bordered={false}>
                    {t(`${openedTeamPlayer.main_leg} leg`)}
                  </Tag>
                </span>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Tag: {
                        defaultColor:
                          'var(--colorTextDescription, rgba(0, 0, 0, 0.45))',
                        defaultBg: 'var(--blue-1, #E6F4FF)',
                        marginXS: 0,
                      },
                    },
                    token: {
                      fontSizeSM: 14,
                    },
                  }}
                >
                  <Tag bordered={false}>{t('no data')}</Tag>
                </ConfigProvider>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex className="team-player-stats-block">
          <Flex gap={17} vertical className="f-grow">
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Height')} </span>
              {openedTeamPlayer?.height ? (
                <Tag
                  bordered={false}
                >{`${openedTeamPlayer?.height} ${t('cm')}`}</Tag>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Tag: {
                        defaultColor:
                          'var(--colorTextDescription, rgba(0, 0, 0, 0.45))',
                        defaultBg: 'var(--blue-1, #E6F4FF)',
                        marginXS: 0,
                      },
                    },
                    token: {
                      fontSizeSM: 14,
                    },
                  }}
                >
                  <Tag bordered={false}>{t('no data')}</Tag>
                </ConfigProvider>
              )}
            </Flex>
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Weight')} </span>
              {openedTeamPlayer?.weight ? (
                <Tag
                  bordered={false}
                >{`${openedTeamPlayer?.weight} ${t('kg')}`}</Tag>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Tag: {
                        defaultColor:
                          'var(--colorTextDescription, rgba(0, 0, 0, 0.45))',
                        defaultBg: 'var(--blue-1, #E6F4FF)',
                        marginXS: 0,
                      },
                    },
                    token: {
                      fontSizeSM: 14,
                    },
                  }}
                >
                  <Tag bordered={false}>{t('no data')}</Tag>
                </ConfigProvider>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex className="team-player-stats-block">
          <Flex gap={17} vertical className="f-grow" justify="space-around">
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('National team')} </span>
              {openedTeamPlayer !== null &&
              openedTeamPlayer?.national_team &&
              openedTeamPlayer?.national_team?.id !== 52 ? (
                <Tag bordered={false}>
                  {openedTeamPlayer?.national_team?.name}
                </Tag>
              ) : openedTeamPlayer?.national_team?.id === 52 ? (
                <Tag bordered={false}>{t('not called up')}</Tag>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Tag: {
                        defaultColor:
                          'var(--colorTextDescription, rgba(0, 0, 0, 0.45))',
                        defaultBg: 'var(--blue-1, #E6F4FF)',
                        marginXS: 0,
                      },
                    },
                    token: {
                      fontSizeSM: 14,
                    },
                  }}
                >
                  <Tag bordered={false}>{t('no data')}</Tag>
                </ConfigProvider>
              )}
            </Flex>
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Contract expires')} </span>
              {openedTeamPlayer?.contract_untill ? (
                <span style={{ fontSize: 16 }}>
                  <Tag bordered={false}>
                    {dayjs(openedTeamPlayer?.contract_untill).format(
                      'DD.MM.YYYY',
                    )}
                  </Tag>
                </span>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Tag: {
                        defaultColor:
                          'var(--colorTextDescription, rgba(0, 0, 0, 0.45))',
                        defaultBg: 'var(--blue-1, #E6F4FF)',
                        marginXS: 0,
                      },
                    },
                    token: {
                      fontSizeSM: 14,
                    },
                  }}
                >
                  <Tag bordered={false}>{t('no data')}</Tag>
                </ConfigProvider>
              )}
            </Flex>
          </Flex>
          {/* <Divider type="vertical" /> */}
        </Flex>
      </Flex>
    </ConfigProvider>
  );
};
export default PersonalInfo;
