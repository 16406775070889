import React from 'react';

import { Col, ConfigProvider, Divider, Flex, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FieldIconBlue } from '../../../../assets/img/FieldIconBlue.svg';

import './index.css';

const ProtocolButton = ({
  playedTime,
  role,
  roleStats = [],
}: {
  playedTime: any | undefined;
  role: any | undefined;
  roleStats: [] | undefined;
}) => {
  const openProtocol = () => {
    // console.log('open protocol');
  };
  const [t] = useTranslation();
  return (
    <Flex
      align="center"
      style={{
        height: '100%',
        flex: 0,
        minWidth: 56,
      }}
      justify="center"
    >
      <Flex vertical style={{ height: '100%' }} justify="center" align="center">
        {roleStats && roleStats.length > 0 ? (
          <ConfigProvider
            theme={{
              token: {
                borderRadius: 8,
                paddingSM: 3,
                controlHeight: 24,
              },
            }}
          >
            <Tooltip
              color="#141414"
              title={() => {
                return roleStats.map((roleStat: any, index: number) => {
                  return (
                    <Row justify={'space-between'} key={index}>
                      <Col style={{ flex: 1, textWrap: 'nowrap' }}>
                        {roleStat.started_at}’–{roleStat.finished_at}’
                      </Col>
                      <Col>
                        <Divider
                          type="vertical"
                          style={{
                            borderColor: 'rgba(255, 255, 255, 0.25)',
                            margin: 'auto',
                            flex: 1,
                          }}
                        />
                      </Col>
                      <Col style={{ flex: 1, textAlign: 'center' }}>
                        {t(roleStat.role)}
                      </Col>
                      <Col>
                        <Divider
                          type="vertical"
                          style={{
                            borderColor: 'rgba(255, 255, 255, 0.25)',
                            margin: 'auto',
                            flex: 1,
                          }}
                        />
                      </Col>
                      <Col
                        style={{ flex: 1, marginLeft: 5, textWrap: 'nowrap' }}
                      >
                        {roleStat.schema}
                      </Col>
                    </Row>
                  );
                });
              }}
              overlayStyle={{
                width: 190,
              }}
            >
              <div
                style={{
                  height: 22,
                  marginLeft: role?.value?.split(', ').lenght > 1 ? -15 : 0,
                  marginRight: role?.value?.split(', ').lenght > 1 ? -10 : 0,
                }}
                className="labelText"
              >
                {role?.value
                  ?.split(', ')
                  .map((r: string) => t(r))
                  .join(', ')}
              </div>
            </Tooltip>
          </ConfigProvider>
        ) : (
          <div
            style={{
              height: 22,
              marginLeft: role?.value?.split(', ').lenght > 1 ? -15 : 0,
              marginRight: role?.value?.split(', ').lenght > 1 ? -10 : 0,
            }}
            className="labelText"
          >
            {role?.value
              ?.split(', ')
              .map((r: string) => t(r))
              .join(', ')}
          </div>
        )}
        <div
          style={{
            height: 30,
            width: 30,
            textAlign: 'center',
            marginTop: 5,
          }}
        >
          <FieldIconBlue
            className="enabledClickable"
            onClick={(e) => {
              e.stopPropagation();
              openProtocol();
            }}
          />
        </div>
        <div style={{ height: 22 }} className="labelText">
          {playedTime?.value
            ? `${Math.round(playedTime?.value / 1000 / 60)}’`
            : ''}
        </div>
      </Flex>
    </Flex>
  );
};
export default ProtocolButton;
