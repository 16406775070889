import React, { useEffect, useRef, useState } from 'react';

import { Flex, Tooltip } from 'antd';

import { ReactComponent as CloseIcon } from 'assets/img/SmallCloseCrossWhite.svg';

const Comet = ({ comet, bounds }: { comet: any; bounds: any }) => {
  const circle1Ref = useRef<any>(null);
  const circle2Ref = useRef<any>(null);
  const [angle, setAngle] = useState(0);
  const [distance, setDistance] = useState<any>(0);
  const [rightCenter, setRightCenter] = useState<any>({ x: 0, y: 0 });
  const [boundsRect, setBoundsRect] = useState<any>({ x: 0, y: 0 });

  const connectCircles = () => {
    const circle1 = circle1Ref.current;
    const circle2 = circle2Ref.current;
    // const line = lineRef.current;
    const b = bounds;
    if (!circle1 || !circle2 || !b) {
      return;
    }
    setBoundsRect(b);

    const rect1 = circle1.getBoundingClientRect();
    const rect2 = circle2.getBoundingClientRect();
    // const boundsRect = bounds.getBoundingClientRect();
    console.log(rect1);
    // Calculate the centers of the circles
    const x1 = rect1.left + rect1.width / 2;
    const y1 = rect1.y + rect1.height / 2;
    const x2 = rect2.left + rect2.width / 2;
    const y2 = rect2.y + rect2.height / 2;
    // Calculate the distance and angle
    const dx = x2 - x1;
    const dy = y2 - y1;
    const d = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    setDistance(d);
    setRightCenter({ x: x2, y: y2 });
    const an = Math.atan2(dy, dx);
    setAngle(an / (Math.PI / 180));
  };
  useEffect(() => {
    connectCircles();
    window.addEventListener('resize', connectCircles);
    return () => {
      window.removeEventListener('resize', connectCircles);
    };
  }, []);
  return (
    <div>
      <Tooltip title={comet.id}>
        <Flex
          align="center"
          justify="center"
          ref={circle1Ref}
          className={`comet head ${comet.result}`}
          style={{
            left: `calc(${comet.coords.finish.x * bounds?.width}px - 5px)`,
            top: `calc(${comet.coords.finish.y * bounds?.height}px - 5px)`,
            zIndex: 10,
          }}
        >
          {comet.result === 'Fail' && <CloseIcon />}
          {/* // do not remove */}
          <div></div>
        </Flex>
      </Tooltip>
      <div
        ref={circle2Ref}
        className={`comet tail ${comet.result}`}
        style={{
          left: `calc(${comet.coords.start.x * 100}% - 1.5px)`,
          top: `calc(${comet.coords.start.y * 100}% - 1.5px)`,
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          width: 10,
          borderBottom: `solid ${comet.result === 'Success' ? 'rgb(105, 177, 255, 0.5)' : 'rgb(175, 178, 184, 0.5)'} ${distance}px`,
          borderLeft: '3.5px solid transparent',
          borderRight: '3.5px solid transparent',
          height: 0,
          left: rightCenter.x - 5 - boundsRect.left || 0,
          top: rightCenter.y - boundsRect.top || 0,
          transformOrigin: '50% 0',
          transform: `rotate(${angle + 90}deg)`,
        }}
      ></div>
    </div>
  );
};
export default Comet;
