import React, { useState } from 'react';

import { Button, Col, ConfigProvider, Flex, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ReactComponent as PlayCircleFilled } from 'assets/img/icons/faPlayCircleFilledWhite.svg';
import { makeLink } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { AppStateType } from 'reducers';
import './index.css';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';
import { getEpisodeWord } from 'types/functions';

import MapWithComets from './MapWithComets';

const MapsAndStats = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  metricsControllerRef,
}: {
  metricsControllerRef: any;
}) => {
  const {
    fromFieldEventsDetails,
    toFieldEventsDetails,
    displayedComets,
    multitoolEvents,
    matchedPointEvents,
    selectedFromFieldCells,
    selectedToFieldCells,
    selectedStat,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    multitoolEventsLoading,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const { setSelectedFromFieldsCells, setSelectedToFieldsCells } =
    teamPlayerStatsSectionReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapsState, setMapsState] = useState('comets');
  const [t] = useTranslation();
  const FIELD_CELLS_FROM = [
    [
      { id: 5, value: undefined },
      { id: 10, value: 10 },
      { id: 15, value: 10 },
      { id: 20, value: undefined },
      { id: 25, value: 10 },
      { id: 30, value: 10 },
    ],
    [
      { id: 4, value: 10 },
      { id: 9, value: 10 },
      { id: 14, value: undefined },
      { id: 19, value: 10 },
      { id: 24, value: 10 },
      { id: 29, value: 10 },
    ],
    [
      { id: 3, value: 10 },
      { id: 8, value: 10 },
      { id: 13, value: 10 },
      { id: 18, value: 10 },
      { id: 23, value: 10 },
      { id: 28, value: 10 },
    ],
    [
      { id: 2, value: 10 },
      { id: 7, value: undefined },
      { id: 12, value: 10 },
      { id: 17, value: 10 },
      { id: 22, value: 10 },
      { id: 27, value: 10 },
    ],
    [
      { id: 1, value: 10 },
      { id: 6, value: 10 },
      { id: 11, value: 10 },
      { id: 16, value: 10 },
      { id: 21, value: undefined },
      { id: 26, value: 10 },
    ],
  ];
  const FIELD_CELLS_TO = [
    [
      { id: 5, value: 10 },
      { id: 10, value: 10 },
      { id: 15, value: 10 },
      { id: 20, value: 10 },
      { id: 25, value: 10 },
      { id: 30, value: 10 },
    ],
    [
      { id: 4, value: 10 },
      { id: 9, value: 10 },
      { id: 14, value: 10 },
      { id: 19, value: 10 },
      { id: 24, value: 10 },
      { id: 29, value: 10 },
    ],
    [
      { id: 3, value: 10 },
      { id: 8, value: 10 },
      { id: 13, value: 10 },
      { id: 18, value: 10 },
      { id: 23, value: 10 },
      { id: 28, value: 10 },
    ],
    [
      { id: 2, value: 10 },
      { id: 7, value: 10 },
      { id: 12, value: 10 },
      { id: 17, value: 10 },
      { id: 22, value: 10 },
      { id: 27, value: 10 },
    ],
    [
      { id: 1, value: 10 },
      { id: 6, value: 10 },
      { id: 11, value: 10 },
      { id: 16, value: 10 },
      { id: 21, value: 10 },
      { id: 26, value: 10 },
    ],
  ];
  const dispatch = useAppDispatch();
  const toggleFromFieldCell = (cellId: number) => {
    if (selectedFromFieldCells.includes(cellId)) {
      dispatch(
        setSelectedFromFieldsCells(
          selectedFromFieldCells.filter((el) => el !== cellId),
        ),
      );
    } else {
      dispatch(setSelectedFromFieldsCells([...selectedFromFieldCells, cellId]));
    }
  };
  const toggleToFieldCell = (cellId: number) => {
    if (selectedToFieldCells.includes(cellId)) {
      dispatch(
        setSelectedToFieldsCells(
          selectedToFieldCells.filter((el: any) => el !== cellId),
        ),
      );
    } else {
      dispatch(setSelectedToFieldsCells([...selectedToFieldCells, cellId]));
    }
  };
  const constructCellClassName = (
    details: any,
    cellId: number,
    selectedCells: number[],
    oppositeCells: number[],
  ) => {
    let className = 'field-cell';
    if (details[cellId]) {
      if (details[cellId]['events'].length) {
        className += ' with-value';
      }
      if (selectedCells.includes(cellId)) {
        className += ' selected';
      } else if (
        oppositeCells &&
        oppositeCells.filter((value) =>
          details[cellId]['opposite'].includes(value),
        ).length
      ) {
        className += ' opposite-selected';
      } else if (
        (selectedCells?.length && !selectedCells.includes(cellId)) ||
        (oppositeCells?.length && !selectedCells.includes(cellId))
      ) {
        className += ' non-selected';
      }
    }
    return className;
  };
  const getButtonText = () => {
    let text = 'Watch';
    if (selectedStat?.as_point) {
      let qty = multitoolEvents.length;
      if (selectedFromFieldCells.length > 0) {
        qty = matchedPointEvents.length;
      }
      text = t('Watch') + ' ' + qty + ' ' + t(getEpisodeWord(qty.toString()));
    } else {
      text =
        t('Watch') +
        ' ' +
        displayedComets.length +
        ' ' +
        t(getEpisodeWord(displayedComets.length.toString()));
    }
    return text;
  };
  const getBackgroundColor = (
    cellDetails: any,
    cell: any,
    selectedCells: any,
    oppositeCells: any[],
  ) => {
    let baseColor;
    if (oppositeCells.length) {
      if (selectedCells?.length && selectedCells.includes(cell.id)) {
        baseColor = `rgba(22, 104, 220, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
      } else if (
        cellDetails[cell.id] &&
        oppositeCells &&
        oppositeCells.filter((value) =>
          cellDetails[cell.id]['opposite'].includes(value),
        ).length
      ) {
        // opposite-selected
        baseColor = `rgba(22, 104, 220, ${0.3 * cellDetails[cell.id]?.opacity + 0.2 || 0})`;
      } else {
        // opposite-not-selected
        baseColor = `rgba(0, 0, 0, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
      }
    } else {
      if (selectedCells?.length && !selectedCells.includes(cell.id)) {
        // non-selected
        baseColor = `rgba(0, 0, 0, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
      } else {
        // with-value
        baseColor = `rgba(22, 104, 220, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
      }
    }
    // console.log(cell);
    return baseColor;
  };
  const resetSelectedFromCells = () => {
    dispatch(setSelectedFromFieldsCells([]));
  };
  const resetSelectedToCells = () => {
    dispatch(setSelectedToFieldsCells([]));
  };
  const { playerId } = useParams();
  const openFilteredEpisodes = () => {
    const link = makeLink(
      [{ id: null }],
      null,
      [],
      undefined,
      'manual',
      playerId,
      null,
    );
    let eventIds: string[] = [];
    if (
      selectedStat?.as_point &&
      selectedFromFieldCells.length === 0 &&
      selectedToFieldCells.length === 0
    ) {
      eventIds = [...multitoolEvents.map((evt) => evt.id)];
    } else {
      selectedFromFieldCells.forEach((cell) => {
        eventIds = [...eventIds, ...fromFieldEventsDetails[cell]['events']];
      });
      selectedToFieldCells.forEach((cell) => {
        eventIds = [...eventIds, ...toFieldEventsDetails[cell]['events']];
      });
    }
    localStorage.setItem(`player_events_${link}`, JSON.stringify(eventIds));
    window.open(link, '_blank');
  };
  return (
    <Flex flex={1} gap={16}>
      {mapsState === 'comets' ? (
        <Flex vertical flex={1} gap={16} justify="flex-start">
          {!selectedStat?.as_point && (
            <Flex gap={16} justify="space-between" style={{ paddingBottom: 8 }}>
              <Flex
                vertical
                gap={6}
                style={{ width: '49%', height: '100%', position: 'relative' }}
              >
                <Flex
                  style={{
                    position: 'absolute',
                    zIndex: 20,
                    top: '40%',
                    left: '43%',
                  }}
                >
                  <ConfigProvider>
                    <Spin
                      spinning={multitoolEventsLoading}
                      // spinning={true}
                      size="large"
                      tip={t('Loading')}
                    >
                      <div style={{ padding: '25px' }}></div>
                    </Spin>
                  </ConfigProvider>
                </Flex>
                <div className="field-class">
                  {FIELD_CELLS_FROM.map((row, index) => {
                    return (
                      <Row style={{ height: '20%' }} key={index}>
                        {row.map((cell) => {
                          return (
                            <Col
                              key={cell.id}
                              span={4}
                              onClick={() => {
                                if (
                                  fromFieldEventsDetails[cell.id] &&
                                  fromFieldEventsDetails[cell.id]['events']
                                    ?.length !== undefined
                                ) {
                                  toggleFromFieldCell(cell.id);
                                }
                              }}
                              className={constructCellClassName(
                                fromFieldEventsDetails,
                                cell.id,
                                selectedFromFieldCells,
                                selectedToFieldCells,
                              )}
                              style={{
                                backgroundColor: getBackgroundColor(
                                  fromFieldEventsDetails,
                                  cell,
                                  selectedFromFieldCells,
                                  selectedToFieldCells,
                                ),
                              }}
                            >
                              <span className="">{cell.id}</span>
                              <div className="value">
                                {(fromFieldEventsDetails[cell.id] &&
                                  fromFieldEventsDetails[cell.id]['events']
                                    ?.length) ||
                                  ''}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  })}
                </div>
                <Flex justify="space-between">
                  <div className="dark-text">{t('Starting zone')}</div>
                  {selectedFromFieldCells.length > 0 && (
                    <div
                      style={{
                        color: 'var(--colorPrimaryBase, #1677FF)',
                      }}
                      className="enabledClickable"
                      onClick={resetSelectedFromCells}
                    >
                      {t('Reset')}
                    </div>
                  )}
                </Flex>
              </Flex>
              <Flex
                vertical
                gap={6}
                style={{ width: '49%', height: '100%', position: 'relative' }}
              >
                <Flex
                  style={{
                    position: 'absolute',
                    zIndex: 20,
                    top: '40%',
                    left: '43%',
                  }}
                >
                  <ConfigProvider>
                    <Spin
                      spinning={multitoolEventsLoading}
                      // spinning={true}
                      size="large"
                      tip={t('Loading')}
                    >
                      <div style={{ padding: '25px' }}></div>
                    </Spin>
                  </ConfigProvider>
                </Flex>
                <div className="field-class">
                  {FIELD_CELLS_TO.map((row, index) => {
                    return (
                      <Row style={{ height: '20%' }} key={index}>
                        {row.map((cell, indexCell) => {
                          return (
                            <Col
                              key={indexCell}
                              span={4}
                              onClick={() => {
                                if (
                                  toFieldEventsDetails[cell.id] &&
                                  toFieldEventsDetails[cell.id]['events']
                                    ?.length !== undefined
                                ) {
                                  toggleToFieldCell(cell.id);
                                }
                              }}
                              className={constructCellClassName(
                                toFieldEventsDetails,
                                cell.id,
                                selectedToFieldCells,
                                selectedFromFieldCells,
                              )}
                              style={{
                                backgroundColor: getBackgroundColor(
                                  toFieldEventsDetails,
                                  cell,
                                  selectedToFieldCells,
                                  selectedFromFieldCells,
                                ),
                              }}
                            >
                              <span>{cell.id}</span>
                              <div className="value">
                                {(toFieldEventsDetails[cell.id] &&
                                  toFieldEventsDetails[cell.id]['events']
                                    ?.length) ||
                                  ''}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  })}
                </div>
                <Flex justify="space-between">
                  <div className="dark-text">{t('Finish zone')}</div>
                  {selectedToFieldCells.length > 0 && (
                    <div
                      style={{
                        color: 'var(--colorPrimaryBase, #1677FF)',
                      }}
                      className="enabledClickable"
                      onClick={resetSelectedToCells}
                    >
                      {t('Reset')}
                    </div>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
          <MapWithComets
            fromFieldEventsDetails={fromFieldEventsDetails}
            toFieldEventsDetails={toFieldEventsDetails}
            as_point={selectedStat?.as_point}
          />
          <Flex justify="flex-end" gap={22} align="center">
            <Flex justify="space-between">
              {selectedStat?.as_point && selectedFromFieldCells.length > 0 && (
                <div
                  style={{
                    color: 'var(--colorPrimaryBase, #1677FF)',
                  }}
                  className="enabledClickable"
                  onClick={resetSelectedFromCells}
                >
                  {t('Reset')}
                </div>
              )}
            </Flex>
            <Button
              type="primary"
              disabled={
                (!selectedStat?.as_point && displayedComets.length === 0) ||
                (selectedStat?.as_point && multitoolEvents.length === 0)
              }
              onClick={openFilteredEpisodes}
              icon={<PlayCircleFilled />}
            >
              {getButtonText()}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};
export default MapsAndStats;
