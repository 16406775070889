import { API_ENDPOINT } from 'types/constants';
import myAxios from 'utils/axios';

export const getPlayerSeasons = async (playerId: string, forStats = false) => {
  const url = `${API_ENDPOINT}api/clubs/players/${playerId}/seasons/?for_stats=${forStats}`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getPlayerRoles = async (
  playerId: string,
  selectedPosition?: string,
  seasonId?: string | null,
  tournamentId?: string | undefined,
  games: string[] = [],
  signal?: AbortSignal,
) => {
  const payload: any = {};
  if (seasonId) {
    payload.season = seasonId;
  }
  if (games) {
    payload.games = games;
  }
  if (selectedPosition) {
    payload.selectedPositions = selectedPosition;
  }
  if (tournamentId) {
    payload.tournament = tournamentId;
  }
  payload.player_id = playerId;
  const url = `${API_ENDPOINT}api/data/placements/player_roles/`;
  try {
    return await myAxios.POST(
      url,
      payload,
      {},
      {},
      'json',
      'application/json',
      signal,
    );
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const PlayersAPI = {
  getPlayerSeasons,
  getPlayerRoles,
};
