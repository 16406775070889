import React, { useRef, useState } from 'react';

import { Flex } from 'antd';

import MapsAndStats from './MapsAndStats';
import MetricsSelector from './MetricsSelector';
import SeasonsAndGamesSelector from './SeasonsAndGamesSelector';

const PlayerMultitool = () => {
  const metricsControllerRef = useRef<any>(null);
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  return (
    <Flex
      gap={16}
      style={{
        width: '100%',
      }}
    >
      <SeasonsAndGamesSelector
        metricsControllerRef={metricsControllerRef}
        selectedMetricFilters={selectedMetricFilters}
        setSelectedMetricFilters={setSelectedMetricFilters}
      />
      <MetricsSelector
        metricsControllerRef={metricsControllerRef}
        selectedMetricFilters={selectedMetricFilters}
        setSelectedMetricFilters={setSelectedMetricFilters}
      />
      <MapsAndStats metricsControllerRef={metricsControllerRef} />
    </Flex>
  );
};
export default PlayerMultitool;
