import React, { useEffect, useState } from 'react';

import { Flex, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { GetMultitoolEvents } from 'actions/playerMultitool.actions';
import AverageStatValueTag from 'components/PlayerPageController/PlayerStatisticsBlock/AverageStatValueTag';
import ConsolidatedStatValueTag from 'components/PlayerPageController/PlayerStatisticsBlock/ConsolidatedStatValueTag';
import { makeLink } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import MetricFilter from 'components/PlayerPageController/PlayerStatisticsBlock/MetricFilter';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import { ReactComponent as DownOutlinedBlack } from '../../../../assets/img/icons/faDownOutlinedBlack.svg';

import './index.css';

const StatisticsDetailsMultitoolBlock = ({
  selectedMetricFilters,
  // selectedSeason,
  setSelectedMetricFilters,
  metricsControllerRef,
  rightMargin = 16,
}: {
  selectedMetricFilters: any;
  selectedSeason: any;
  setSelectedMetricFilters: any;
  metricsControllerRef: any;
  rightMargin?: number;
}) => {
  const { playerId } = useParams();
  const {
    statsState_stats,
    teamPlayerStats_stats,
    timeOnField_stats,
    selectedRowKeys_stats,
    timeOnFieldByPosition_stats,
    loadingPercentiles_stats,
    selectedPosition_stats,
    selectedStat,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const {
    setSelectedStat,
    setSelectedFromFieldsCells,
    setSelectedToFieldsCells,
    setDisplayedComets,
    setMatchedPointEvents,
    setFromFieldEventsDetails,
    setToFieldEventsDetails,
    setMultitoolEvents,
  } = teamPlayerStatsSectionReducer.actions;
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const showAveragesTag = openedTeamPlayer && openedTeamPlayer.team.sport !== 2;
  const toggleSelectedStat = (stat: any) => {
    if (selectedStat?.key !== stat.key) {
      dispatch(setSelectedStat({ key: stat.key, as_point: stat.as_point }));
      setSelectedMetricFilters([]);
      if (stat?.percent !== null && stat?.percent !== undefined) {
        setHoveredMetricFilters(() => [stat.key]);
      }
    }
  };
  const dispatch = useAppDispatch();
  const makeLinkAndNavigate = (stat: any) => {
    const metricStringSuccess = `${stat.key}_success`;
    const metricStringFail = `${stat.key}_fail`;
    let finalMetric = [stat];
    if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringSuccess)
    ) {
      finalMetric = [{ key: metricStringSuccess }];
    } else if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringFail)
    ) {
      finalMetric = [{ key: metricStringFail }];
    }
    const link = makeLink(
      [{ id: null }],
      openedTeamPlayer.team,
      finalMetric,
      undefined,
      '',
      playerId,
      selectedPosition_stats,
    );
    localStorage.setItem(
      `player_matches_${link}`,
      JSON.stringify(selectedRowKeys_stats),
    );
    window.open(link, '_blank');
  };
  const toggleSelectedMetric = (
    metricString: string,
    removedMetricString: string,
    metric: string,
    asPoint: boolean,
  ) => {
    if (!selectedStat || (selectedStat && selectedStat.key !== metric)) {
      dispatch(setSelectedStat({ key: metric, as_point: asPoint }));
    }
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev: any) =>
          prev.filter((el: any) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters(() => {
          return [metricString];
        });
      } else {
        setSelectedMetricFilters((prev: any) => [
          ...prev.filter((p: any) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
  };
  const showStat = (stat: any) => {
    return (
      openedTeamPlayer &&
      (stat.for_sport?.length === 0 ||
        (stat.for_sport?.length &&
          stat.for_sport.includes(openedTeamPlayer?.team.sport))) &&
      !stat.excluded_sports?.includes(openedTeamPlayer?.team.sport)
    );
  };
  const onMouseEnter = (e: any, stat: any) => {
    e.preventDefault();
    if (stat?.percent !== null && stat?.percent !== undefined) {
      setHoveredMetricFilters((prev) => [...prev, stat.key]);
    }
  };
  // const controllerRef = useRef<any>(null);
  useEffect(() => {
    const controller: AbortController = new AbortController();
    if (selectedStat && playerId) {
      if (metricsControllerRef?.current) {
        metricsControllerRef.current.abort();
      }
      metricsControllerRef.current = controller;
      dispatch(setSelectedFromFieldsCells([]));
      dispatch(setSelectedToFieldsCells([]));
      dispatch(setDisplayedComets([]));
      dispatch(setMatchedPointEvents([]));
      dispatch(setFromFieldEventsDetails([]));
      dispatch(setToFieldEventsDetails([]));
      dispatch(setMultitoolEvents([]));
      dispatch(
        GetMultitoolEvents(
          selectedStat.key,
          playerId,
          selectedRowKeys_stats || [],
          selectedPosition_stats,
          selectedMetricFilters,
          metricsControllerRef.current.signal,
        ),
      );
      return () => {
        metricsControllerRef.current.abort();
      };
    }
  }, [selectedStat, selectedPosition_stats, selectedMetricFilters]);
  return (
    <Flex
      vertical
      style={{ margin: `0 ${rightMargin}px 8px 16px`, alignSelf: 'stretch' }}
    >
      {teamPlayerStats_stats
        ?.filter((stat: any) => showStat(stat))
        ?.map((stat: any, index: number) => {
          return (
            <div key={index}>
              <Flex
                key={index}
                justify="flex-start"
                style={{ width: '100%', margin: '2px 0px' }}
              >
                <Flex
                  justify="space-between"
                  style={{ width: '100%' }}
                  align="center"
                  gap={8}
                  className={`w100 player-profile-metric-row parent ${selectedStat?.key === stat.key && 'metric-selected'}`}
                  onMouseEnter={(e: any) => {
                    onMouseEnter(e, stat.stat);
                  }}
                  onMouseLeave={() => {
                    setHoveredMetricFilters((prev) =>
                      prev.filter((el: string) => el !== stat.key),
                    );
                  }}
                  onClick={() => toggleSelectedStat(stat)}
                >
                  <Flex
                    style={{
                      marginLeft: 0,
                      overflow: 'hidden',
                    }}
                    align="center"
                    justify="flex-start"
                    gap={8}
                    flex={1}
                    className={`${stat.children?.length ? 'enabledClickable' : ''}`}
                  >
                    {stat.label}
                    {stat.children?.length && (
                      <DownOutlinedBlack
                        style={{
                          zIndex: 20,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            stat.children?.length &&
                            expandedStats.includes(stat.key)
                          ) {
                            setExpandedStats((prev) =>
                              prev.filter((item: string) => item !== stat.key),
                            );
                          } else {
                            setExpandedStats((prev) => [...prev, stat.key]);
                          }
                        }}
                        className={`enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                      />
                    )}
                  </Flex>
                  <Flex>
                    <MetricFilter
                      metric={stat.key}
                      asPoint={stat.as_point}
                      selectedMetricFilters={selectedMetricFilters}
                      hoveredMetricFilter={hoveredMetricFilter}
                      toggleSelectedMetric={toggleSelectedMetric}
                    />
                  </Flex>
                  <Flex gap={8} style={{ zIndex: 10 }}>
                    {statsState_stats === 'loading' ||
                    statsState_stats === 'init' ? (
                      <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                        <Skeleton.Button
                          active
                          style={{ minWidth: 130, minHeight: 38 }}
                        />
                      </div>
                    ) : (
                      <ConsolidatedStatValueTag
                        showPth={
                          openedTeamPlayer && openedTeamPlayer.team.sport !== 2
                        }
                        stat={stat.stat || stat}
                        makeLink={makeLinkAndNavigate}
                        loadingPercentiles={loadingPercentiles_stats}
                        selectedMetricFilters={selectedMetricFilters}
                        selectedPositions={selectedPosition_stats}
                      />
                    )}
                    {openedTeamPlayer &&
                      openedTeamPlayer.team.sport !== 2 &&
                      (statsState_stats === 'loading' ||
                      statsState_stats === 'init' ? (
                        <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                          <Skeleton.Button
                            active
                            style={{
                              maxWidth: 56,
                              minWidth: 56,
                              minHeight: 38,
                            }}
                          />
                        </div>
                      ) : (
                        <AverageStatValueTag
                          stat={stat.stat}
                          loadingPercentiles={loadingPercentiles_stats}
                          selectedMetricFilters={selectedMetricFilters}
                          selectedPositions={selectedPosition_stats}
                          timeOnField={timeOnField_stats}
                          timeOnFieldByPosition={
                            selectedPosition_stats &&
                            timeOnFieldByPosition_stats[selectedPosition_stats]
                          }
                        />
                      ))}
                  </Flex>
                </Flex>
              </Flex>
              {stat.children?.length > 0 && (
                <Flex
                  key={`${index}-x`}
                  vertical
                  style={{ width: '100%', marginBottom: 2 }}
                  gap={4}
                  className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
                >
                  {stat.children
                    .filter((child: any) => showStat(child))
                    .map((child: any, index: number) => {
                      return (
                        <Flex
                          key={index}
                          justify="space-between"
                          style={{ width: '100%' }}
                          gap={8}
                          className="child-wrapper"
                        >
                          <Flex
                            style={{
                              marginLeft: 20,
                              overflow: 'hidden',
                            }}
                            align="center"
                            justify="space-between"
                            gap={8}
                            flex={1}
                            className={`w100 player-profile-metric-row child ${selectedStat?.key === child.key && 'metric-selected'}`}
                            onMouseEnter={(e: any) => {
                              onMouseEnter(e, child.stat.stat || child.stat);
                            }}
                            onMouseLeave={() => {
                              const s = child.stat.stat || child.stat;
                              setHoveredMetricFilters((prev) =>
                                prev.filter((el: string) => el !== s.key),
                              );
                            }}
                            onClick={() => toggleSelectedStat(child)}
                          >
                            <Flex className="elipsis-text">{child.label}</Flex>
                            <MetricFilter
                              metric={child.key}
                              asPoint={child.as_point}
                              selectedMetricFilters={selectedMetricFilters}
                              hoveredMetricFilter={hoveredMetricFilter}
                              toggleSelectedMetric={toggleSelectedMetric}
                            />
                          </Flex>
                          <Flex gap={8} style={{ zIndex: 10 }}>
                            {statsState_stats === 'loading' ||
                            statsState_stats === 'init' ? (
                              <>
                                <div
                                  style={{
                                    borderRadius: 8,
                                    overflow: 'hidden',
                                  }}
                                >
                                  <Skeleton.Button
                                    active
                                    style={{ minWidth: 130, minHeight: 38 }}
                                  />
                                </div>
                                <div
                                  style={{
                                    borderRadius: 8,
                                    overflow: 'hidden',
                                  }}
                                >
                                  <Skeleton.Button
                                    active
                                    style={{
                                      maxWidth: 56,
                                      minWidth: 56,
                                      minHeight: 38,
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <ConsolidatedStatValueTag
                                  showPth={
                                    openedTeamPlayer &&
                                    openedTeamPlayer.team.sport !== 2
                                  }
                                  stat={child?.stat?.stat || child?.stat}
                                  loadingPercentiles={loadingPercentiles_stats}
                                  selectedMetricFilters={selectedMetricFilters}
                                  makeLink={makeLinkAndNavigate}
                                  selectedPositions={selectedPosition_stats}
                                />
                                {showAveragesTag && (
                                  <AverageStatValueTag
                                    stat={child?.stat?.stat || child?.stat}
                                    loadingPercentiles={
                                      loadingPercentiles_stats
                                    }
                                    selectedMetricFilters={
                                      selectedMetricFilters
                                    }
                                    selectedPositions={selectedPosition_stats}
                                    timeOnField={timeOnField_stats}
                                    timeOnFieldByPosition={
                                      selectedPosition_stats &&
                                      timeOnFieldByPosition_stats[
                                        selectedPosition_stats
                                      ]
                                    }
                                  />
                                )}
                              </>
                            )}
                          </Flex>
                        </Flex>
                      );
                    })}
                </Flex>
              )}
            </div>
          );
        })}
    </Flex>
  );
};
export default StatisticsDetailsMultitoolBlock;
