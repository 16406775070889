import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Row,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';

import { ReactComponent as ChevronRight } from '../../../../assets/img/icons/faChevronRight.svg';
import { ReactComponent as PlayCircleFilled } from '../../../../assets/img/icons/faPlayCircleFilled.svg';
import { ReactComponent as PlayCircleFilledDisabled } from '../../../../assets/img/icons/faPlayCircleFilledDisabled.svg';
import GameEventsTags from '../GameEventsTags';
import MatchFieldEventsField from '../MatchFieldEventsField';
import ProtocolButton from '../ProtocolButton';
import StatValueTag from '../StatValueTag';

import './index.css';

const MatchRow = ({ match, matches }: { match: any; matches: any }) => {
  const {
    showAllGameEventCoordinates,
    clearGameEventCoordinates,
    leaveSingleEvent,
    showGameEventCoordinates,
    setGameEventCoordinates,
  } = teamPlayerReducer.actions;
  const { setStatsCustomGameList } = teamReducer.actions;
  const { expandedGameClickedId } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const dispatch = useDispatch();
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  const { playerId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openPhasesTooltip, setOpenPhasesTooltip] = useState(false);
  const [t] = useTranslation();
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  const { setExpandedGamesPlayer } = teamPlayerReducer.actions;
  const { expandedGames } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { gameEventCoordinates } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const clearAllFiltersCallback = (match: any, showAllEvents = true) => {
    setSelectedMetricFilters((prev) =>
      prev.filter((filterString: string) => !filterString.startsWith(match.id)),
    );
    if (showAllEvents) {
      dispatch(
        showAllGameEventCoordinates({
          matchId: match.id,
        }),
      );
    }
  };
  useEffect(() => {
    if (expandedGameClickedId !== null) {
      const gameDiv = document.getElementById(expandedGameClickedId.id);
      if (gameDiv) {
        gameDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
      if (expandedGameClickedId.expanded) {
        matches
          ?.filter((match: any) => match.id === expandedGameClickedId.id)[0]
          ?.stats.filter(
            (stat: any) =>
              metricsRegistry?.filter(
                (regStat: any) =>
                  stat.key === regStat.key &&
                  regStat.visible_in.includes('player'),
              ).length > 0,
          )
          ?.map((stat: any) => {
            if (stat.value) {
              if (stat.details) {
                getEventCoordinates(
                  expandedGameClickedId.id,
                  stat.details.success.key,
                  stat.key,
                  ['success'],
                );
                getEventCoordinates(
                  expandedGameClickedId.id,
                  stat.details.fail.key,
                  stat.key,
                  ['fail'],
                );
              } else {
                getEventCoordinates(
                  expandedGameClickedId.id,
                  stat.key,
                  stat.key,
                );
              }
            }
          });
        dispatch(setExpandedGamesPlayer([expandedGameClickedId.id]));
      }
    }
  }, [matches]);
  const toggleExpanded = (match: any) => {
    if (expandedGames.includes(match.id)) {
      dispatch(
        setExpandedGamesPlayer(expandedGames.filter((el) => el !== match.id)),
      );
      clearAllFiltersCallback(match.id, false);
    } else {
      populateCoordinates(match);
      dispatch(setExpandedGamesPlayer([...expandedGames, match.id]));
    }
  };
  const populateCoordinates = (match: any) => {
    if (gameEventCoordinates[match.id] === undefined) {
      populateAllMetrics(match);
    }
  };
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const makeLink = (
    match: any,
    team?: any,
    stat?: any,
    part?: any,
    phase?: any,
  ) => {
    let link = `/player?eventId=${match.id}`;
    if (team) {
      if (part === 'highlights') {
        link = `${link}&teamId=${team?.id}`;
      } else {
        link = `${link}&teamId=${team?.id}`;
      }
    }
    if (stat) {
      link = `${link}&m=${stat.key}`;
    }
    if (part) {
      link = `${link}&p=${part}`;
    }
    if (phase) {
      link = `${link}&ph=${phase}`;
    }
    link = `${link}&pl=${openedTeamPlayer.id}`;

    dispatch(AC_setIntermediateActivitiesAction(match ? [match.id] : []));
    dispatch(setStatsCustomGameList(match ? [match.id] : []));
    return link;
  };
  const getEventCoordinates = (
    matchId: string,
    metrics: string[],
    stateMetrics: string[],
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    result: string[] = ['all'],
    match?: any,
  ) => {
    setLoadingCoordinates(true);
    EventsAPI.getPlayerGameEventCoordinates(
      matchId,
      metrics,
      openedTeamPlayer.id,
      result,
    )
      .then((response: any) => {
        match.stats.map((stat: any) => {
          if (stat.value) {
            if (stat.details) {
              if (stat.details.success.value) {
                dispatch(
                  setGameEventCoordinates({
                    matchId: matchId,
                    result: 'success',
                    metric: stat.key,
                    coordinates: response.data[stat.details.success.key],
                  }),
                );
              }
              if (stat.details.fail.value) {
                dispatch(
                  setGameEventCoordinates({
                    matchId: matchId,
                    result: 'fail',
                    metric: stat.key,
                    coordinates: response.data[stat.details.fail.key],
                  }),
                );
              }
            } else {
              dispatch(
                setGameEventCoordinates({
                  matchId: matchId,
                  result: 'all',
                  metric: stat.key,
                  coordinates: response.data[stat.key],
                }),
              );
            }
          }
        });
        setLoadingCoordinates(false);
      })
      .catch(() => setLoadingCoordinates(false));
  };
  const populateAllMetrics = (match: any) => {
    setLoadingCoordinates(true);
    const keys: any[] = [];
    match?.stats
      ?.filter(
        (stat: any) =>
          metricsRegistry?.filter(
            (regStat: any) =>
              stat.key === regStat.key && regStat.visible_in.includes('player'),
          ).length > 0,
      )
      ?.forEach((stat: any) => {
        if (stat.value) {
          if (stat.details) {
            keys.push(stat.details.success.key);
            keys.push(stat.details.fail.key);
          } else {
            keys.push(stat.key);
          }
        }
      });
    const statKeys = keys.filter((stat: any) => stat !== undefined);
    getEventCoordinates(
      match.id,
      statKeys,
      statKeys,
      ['all', 'fail', 'success'],
      match,
    );
    setLoadingCoordinates(false);
  };
  const toggleSelectedMetric = async (
    match: any,
    metricString: string,
    matchId: string,
    metricObj: any,
    result: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
        await dispatch(
          showAllGameEventCoordinates({
            matchId: matchId,
          }),
        );
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev) =>
          prev.filter((el) => el !== metricString),
        );
        await dispatch(
          clearGameEventCoordinates({
            matchId: matchId,
            result: result,
            metric: metricObj.key,
          }),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
        await dispatch(
          leaveSingleEvent({
            matchId: matchId,
            result: result,
            metric: metricObj.key,
          }),
        );
      } else {
        setSelectedMetricFilters((prev) => [...prev, metricString]);
        await dispatch(
          showGameEventCoordinates({
            matchId: matchId,
            result: result,
            metric: metricObj.key,
          }),
        );
      }
    }
  };
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const [loadingCoordinates, setLoadingCoordinates] = useState(false);
  const { setExpandedGameClickedId } = teamReducer.actions;

  const renderMetricFilters = (
    match: any,
    metric: string,
    matchId: string,
    metricObj: any,
  ) => {
    const metricStringSuccess = `${matchId}_${metric}_success`;
    const metricStringFail = `${matchId}_${metric}_fail`;
    if (
      (selectedMetricFilters &&
        !loadingCoordinates &&
        (selectedMetricFilters.includes(metricStringSuccess) ||
          selectedMetricFilters.includes(metricStringFail))) ||
      hoveredMetricFilter.includes(`${matchId}_${metric}`)
    ) {
      return (
        <Flex gap={4}>
          <Flex className="success-checkbox" justify="center">
            <ConfigProvider
              theme={{
                token: {},
              }}
            >
              <Checkbox
                checked={selectedMetricFilters.includes(metricStringSuccess)}
                disabled={loadingCoordinates}
                onClick={() => {
                  toggleSelectedMetric(
                    match,
                    metricStringSuccess,
                    matchId,
                    metricObj,
                    metricObj.details ? 'success' : 'all',
                  );
                }}
              />
            </ConfigProvider>
          </Flex>
          {metricObj.details && (
            <Flex className="fail-checkbox" justify="center">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: 'grey',
                  },
                }}
              >
                <Checkbox
                  checked={selectedMetricFilters.includes(metricStringFail)}
                  disabled={loadingCoordinates}
                  onClick={() =>
                    toggleSelectedMetric(
                      match,
                      metricStringFail,
                      matchId,
                      metricObj,
                      'fail',
                    )
                  }
                />
              </ConfigProvider>
            </Flex>
          )}
        </Flex>
      );
    } else {
      return <Flex children={undefined}></Flex>;
    }
  };
  const matchHasEvents = (match: any) => {
    return (
      match.stats?.filter(
        (stat: any) =>
          (stat.key === 'yellow_cards' &&
            stat.value !== null &&
            stat.value > 0) ||
          (stat.key === 'red_cards' && stat.value !== null && stat.value > 0) ||
          (stat.key === 'goal' && stat.value !== null && stat.value > 0) ||
          (stat.key === 'goal_pass' && stat.value !== null && stat.value > 0),
      ).length > 0
    );
  };
  return (
    <div
      className={`team-match-wrapper ${expandedGames.includes(match.id) ? 'pressed' : 'enabledClickable'}`}
      onClick={() => {
        if (!expandedGames.includes(match.id)) {
          toggleExpanded(match);
        }
      }}
      id={match.id}
      key={match.id}
    >
      <div
        onMouseEnter={() => {
          populateCoordinates(match);
        }}
        className="enabledClickable"
        onClick={() => {
          if (expandedGames.includes(match.id)) {
            toggleExpanded(match);
          }
        }}
      >
        <div
          style={{
            width: 32,
            height: 32,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexShrink: 0,
          }}
        >
          <ChevronRight
            className={`${expandedGames.includes(match.id) ? 'rotate90' : 'rotate0'} `}
            fill="var(--colorLink, #1677FF)"
          />
        </div>
        <div className="match-details">
          <div>{match.date}</div>
          <div className="elipsis-text">{match.tournament?.country}</div>
          <div style={{ textWrap: 'wrap' }}>{match.tournament?.name}</div>
        </div>
        <div className="match-participants-container">
          <div style={{ textAlign: 'end', flex: 1, textWrap: 'nowrap' }}>
            {match.teams[0].name}
          </div>
          <div
            className="score"
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div>{match.teams[0].score}</div>:<div>{match.teams[1].score}</div>
          </div>
          <div style={{ textAlign: 'start', flex: 1, textWrap: 'nowrap' }}>
            {match.teams[1].name}
          </div>
        </div>
        <Flex align="center">
          <Flex
            // vertical
            // gap={6}
            style={{ height: '96px' }}
            justify="flex-end"
          >
            <GameEventsTags
              match={match}
              hasEvents={matchHasEvents(match)}
              playerId={playerId || ''}
              isExpanded={expandedGames.includes(match.id)}
            />
            <ProtocolButton
              playedTime={
                openedTeamPlayer &&
                openedTeamPlayer.team.sport !== 2 &&
                match.stats?.filter(
                  (stat: any) => stat.key === 'played_time',
                )[0]
              }
              roleStats={
                openedTeamPlayer &&
                openedTeamPlayer.team.sport !== 2 &&
                match.roles
              }
              role={
                openedTeamPlayer &&
                openedTeamPlayer.team.sport !== 2 &&
                match.stats?.filter((stat: any) => stat.key === 'role')[0]
              }
            />
          </Flex>
          <Divider type="vertical" style={{ height: 80 }} />
          <Flex align="center" className="buttons-controller">
            <div
              style={{
                height: 32,
              }}
              className="enabledClickable"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setExpandedGameClickedId({
                    id: match.id,
                    expanded: expandedGames.includes(match.id),
                  }),
                );
                window.open(
                  makeLink(
                    match,
                    { id: openedTeamPlayer.team.id },
                    null,
                    'full',
                  ),
                  '_blank',
                );
              }}
            >
              <PlayCircleFilled />
              {t('Match')}
            </div>
            <div
              style={{
                height: 32,
              }}
              className="enabledClickable"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setExpandedGameClickedId({
                    id: match.id,
                    expanded: expandedGames.includes(match.id),
                  }),
                );
                window.open(
                  makeLink(match, { id: openedTeamPlayer.team.id }, null, null),
                  '_blank',
                );
              }}
            >
              <PlayCircleFilled />
              {t('All actions')}
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorText: 'black',
                  paddingXS: 4,
                  paddingSM: 8,
                },
              }}
            >
              <Tooltip
                title={
                  <ConfigProvider
                    theme={{
                      token: {
                        colorText: 'black',
                      },
                    }}
                  >
                    <Flex
                      vertical
                      className="phase-hover"
                      style={{ color: 'black' }}
                    >
                      <div className="title-grey-small no-hover">
                        {t('Defense a')}
                      </div>
                      <Flex
                        align="center"
                        gap={8}
                        style={{
                          height: 32,
                        }}
                        className="disabled"
                        onClick={(e) => {
                          e.stopPropagation();
                          // dispatch(
                          //   setExpandedGameClickedId({
                          //     id: match.id,
                          //     expanded: expanded.includes(match.id),
                          //   }),
                          // );
                          // navigate(
                          // navigate(
                          //   makeLink(
                          //     match,
                          //     { id: openedTeamPlayer.team.id },
                          //     null,
                          //     null,
                          //     'defence',
                          //   ),
                          // );
                        }}
                      >
                        <PlayCircleFilledDisabled />
                        {t('Defense a')}
                      </Flex>
                      <Flex
                        align="center"
                        gap={8}
                        style={{
                          height: 32,
                        }}
                        className="disabled"
                        onClick={(e) => {
                          e.stopPropagation();
                          // dispatch(
                          //   setExpandedGameClickedId({
                          //     id: match.id,
                          //     expanded: expanded.includes(match.id),
                          //   }),
                          // );
                          // navigate(
                          //   makeLink(
                          //     match,
                          //     { id: openedTeamPlayer.team.id },
                          //     null,
                          //     null,
                          //     'pos_defense',
                          //   ),
                          // );
                        }}
                      >
                        <PlayCircleFilledDisabled />
                        {t('Positional defense')}
                      </Flex>
                      <Flex
                        align="center"
                        gap={8}
                        style={{
                          height: 32,
                        }}
                        className="enabledClickable"
                        onClick={(e) => {
                          e.stopPropagation();
                          // dispatch(
                          //   setExpandedGameClickedId({
                          //     id: match.id,
                          //     expanded: expanded.includes(match.id),
                          //   }),
                          // );
                          window.open(
                            makeLink(
                              match,
                              { id: openedTeamPlayer.team.id },
                              null,
                              null,
                              'to_defense',
                            ),
                            '_blank',
                          );
                        }}
                      >
                        <PlayCircleFilled />
                        {t('Transition to defense')}
                      </Flex>
                      <Flex
                        align="center"
                        gap={8}
                        style={{
                          height: 32,
                        }}
                        className="disabled"
                        onClick={(e) => {
                          e.stopPropagation();
                          // dispatch(
                          //   setExpandedGameClickedId({
                          //     id: match.id,
                          //     expanded: expanded.includes(match.id),
                          //   }),
                          // );
                          // navigate(
                          //   makeLink(
                          //     match,
                          //     { id: openedTeamPlayer.team.id },
                          //     null,
                          //     null,
                          //     'defense_standards',
                          //   ),
                          // );
                        }}
                      >
                        <PlayCircleFilledDisabled />
                        {t('Defensive set-pieces')}
                      </Flex>
                      <Divider style={{ margin: '0 0 0 0' }} />
                      <div className="title-grey-small no-hover">
                        {t('Attack')}
                      </div>
                      <Flex
                        align="center"
                        gap={8}
                        style={{
                          height: 32,
                        }}
                        className="disabled"
                        onClick={(e) => {
                          e.stopPropagation();
                          // dispatch(
                          //   setExpandedGameClickedId({
                          //     id: match.id,
                          //     expanded: expanded.includes(match.id),
                          //   }),
                          // );
                          // navigate(
                          //   makeLink(
                          //     match,
                          //     { id: openedTeamPlayer.team.id },
                          //     null,
                          //     null,
                          //     'attack',
                          //   ),
                          // );
                        }}
                      >
                        <PlayCircleFilledDisabled />
                        {t('Attack')}
                      </Flex>
                      <Flex
                        align="center"
                        gap={8}
                        style={{
                          height: 32,
                        }}
                        className="disabled"
                        onClick={(e) => {
                          e.stopPropagation();
                          // dispatch(
                          //   setExpandedGameClickedId({
                          //     id: match.id,
                          //     expanded: expanded.includes(match.id),
                          //   }),
                          // );
                          // navigate(
                          // navigate(
                          //   makeLink(
                          //     match,
                          //     { id: openedTeamPlayer.team.id },
                          //     null,
                          //     null,
                          //     'pos_attack',
                          //   ),
                          // );
                        }}
                      >
                        <PlayCircleFilledDisabled />
                        {t('Positional attack')}
                      </Flex>
                      <Flex
                        align="center"
                        gap={8}
                        style={{
                          height: 32,
                        }}
                        className="enabledClickable"
                        onClick={(e) => {
                          e.stopPropagation();
                          // dispatch(
                          //   setExpandedGameClickedId({
                          //     id: match.id,
                          //     expanded: expanded.includes(match.id),
                          //   }),
                          // );
                          window.open(
                            makeLink(
                              match,
                              { id: openedTeamPlayer.team.id },
                              null,
                              null,
                              'to_attack',
                            ),
                            '_blank',
                          );
                        }}
                      >
                        <PlayCircleFilled />
                        {t('Transition to attack')}
                      </Flex>
                      <Flex
                        align="center"
                        gap={8}
                        style={{
                          height: 32,
                        }}
                        className="disabled"
                        onClick={(e) => {
                          e.stopPropagation();
                          // dispatch(
                          //   setExpandedGameClickedId({
                          //     id: match.id,
                          //     expanded: expanded.includes(match.id),
                          //   }),
                          // );
                          // navigate(
                          //   makeLink(
                          //     match,
                          //     { id: openedTeamPlayer.team.id },
                          //     null,
                          //     null,
                          //     'attack_standards',
                          //   ),
                          // );
                        }}
                      >
                        <PlayCircleFilledDisabled />
                        {t('Attacking set-pieces')}
                      </Flex>
                    </Flex>
                  </ConfigProvider>
                }
                placement="right"
                trigger={['click']}
                color="white"
                arrow={false}
                onOpenChange={() => setOpenPhasesTooltip((prev) => !prev)}
                autoAdjustOverflow={false}
              >
                <Flex
                  style={{
                    height: 32,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                  className={`enabledClickable ${openPhasesTooltip ? 'buttons-controller-active' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <PlayCircleFilled />
                  {t('Phases...')}
                </Flex>
              </Tooltip>
            </ConfigProvider>
          </Flex>
        </Flex>
      </div>
      {expandedGames.includes(match.id) && (
        <Flex
          gap={12}
          style={{
            backgroundColor: 'var(--colorBgContainer)',
            padding: 12,
            borderRadius: 8,
            width: '100%',
          }}
          align="center"
          justify="space-between"
        >
          <Row gutter={[12, 12]} className="w100">
            {metricsRegistry
              .filter((stat: any) => stat.visible_in.includes('player'))
              .sort(
                (a, b) => a.player_profile_ordering - b.player_profile_ordering,
              )
              .map((metric: any) => {
                const s = match.stats?.filter(
                  (stat: any) => stat.key === metric.key,
                )[0];
                if (s) {
                  return (
                    <Col
                      span={6}
                      onMouseEnter={(e: any) => {
                        e.preventDefault();
                        setHoveredMetricFilters((prev) => [
                          ...prev,
                          `${match.id}_${s.name}`,
                        ]);
                      }}
                      onMouseLeave={() => {
                        setHoveredMetricFilters((prev) =>
                          prev.filter(
                            (el: string) => el !== `${match.id}_${s.name}`,
                          ),
                        );
                      }}
                    >
                      <Flex
                        className="stat-header"
                        justify="space-between"
                        style={{
                          marginBottom: 2,
                        }}
                      >
                        <span className=" elipsis-text">
                          {t(metric.name_plural || metric.name)}
                        </span>
                        {s.value ? (
                          renderMetricFilters(match, s.name, match.id, s)
                        ) : (
                          <></>
                        )}
                      </Flex>
                      <StatValueTag
                        stat={s}
                        match={match}
                        expanded={expandedGames}
                        makeLink={makeLink}
                        selectedMetricFilters={selectedMetricFilters}
                      />
                    </Col>
                  );
                }
              })}
          </Row>
          <MatchFieldEventsField
            gameId={match.id}
            showClearFilterButton={
              selectedMetricFilters.filter((filterString) =>
                filterString.startsWith(match.id),
              ).length > 0
            }
            updateDots={expandedGames.includes(match.id)}
            clearAllFiltersCallback={() => clearAllFiltersCallback(match)}
          />
        </Flex>
      )}
    </div>
  );
};
export default MatchRow;
