import React, { useEffect, useState } from 'react';

import { ConfigProvider, Divider, Flex, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeStatsPlaceholders } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import StatisticsDetailsMultitoolBlock from '../StatisticsDetailsMultitoolBlock';

const MetricsSelector = ({
  metricsControllerRef,
  selectedMetricFilters,
  setSelectedMetricFilters,
}: {
  metricsControllerRef: any;
  selectedMetricFilters: any;
  setSelectedMetricFilters: any;
}) => {
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { setTeamPlayerStats_stats } = teamPlayerStatsSectionReducer.actions;
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  useEffect(() => {
    dispatch(setTeamPlayerStats_stats(makeStatsPlaceholders(t)));
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSeason, setSelectedSeason] = useState<string | number>();
  const [metricsState, setMetricsState] = useState(t('Statistics'));
  return (
    <Flex
      gap={0}
      vertical
      justify="flex-start"
      style={{
        width: 404,
        // paddingLeft: 8,
        // paddingRight: 12,
        background: 'white',
        borderRadius: 12,
      }}
    >
      <Flex
        align="center"
        justify="center"
        style={{ marginLeft: 8, marginRight: 8, height: 48 }}
      >
        <ConfigProvider
          theme={{
            components: {
              Segmented: {
                trackBg: 'var(--segmentedGroupBg)',
                itemColor: 'red',
              },
            },
          }}
        >
          <Segmented
            style={{ width: '100%' }}
            size="middle"
            color="black"
            block
            height={32}
            value={metricsState}
            options={[t('Statistics'), t('Context')]}
            onChange={(value) => {
              setMetricsState(value); // string
            }}
          />
        </ConfigProvider>
      </Flex>
      <Flex
        style={{
          paddingLeft: 16,
          paddingRight: 8,
        }}
      >
        <Flex flex={1} className="stats-name">
          {t('Actions')}
        </Flex>
        <div
          className="stats-header"
          style={{
            minWidth: 130,
            flex:
              openedTeamPlayer && openedTeamPlayer.team.sport === 2
                ? 'unset'
                : 1,
            textAlign: 'start',
          }}
        >
          {t('Total')}
        </div>
        <div className="stats-header">{t('90 min')}</div>
      </Flex>
      <Divider style={{ marginBottom: 7, marginTop: 4 }} />
      <StatisticsDetailsMultitoolBlock
        selectedMetricFilters={selectedMetricFilters}
        selectedSeason={selectedSeason}
        setSelectedMetricFilters={setSelectedMetricFilters}
        rightMargin={8}
        metricsControllerRef={metricsControllerRef}
      />
    </Flex>
  );
};
export default MetricsSelector;
