import { ThunkAction } from 'redux-thunk';

import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';

import { GetSingleSeasonSelected_stats } from './persona.actions';

const {
  setMultitoolEvents,
  setFromFieldEventsDetails,
  setToFieldEventsDetails,
  setMultitoolEventsLoading,
} = teamPlayerStatsSectionReducer.actions;

export const GetMultitoolEvents =
  (
    metric: string,
    playerId: string,
    games: any = [],
    selectedPosition_stats: string | null = '',
    selectedMetricFilters: any,
    abortSignal: AbortSignal,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setMultitoolEventsLoading(true));
      const seasonId = await dispatch(GetSingleSeasonSelected_stats());
      const response = await EventsAPI.getEventDetailsForMultitool({
        metric: metric,
        playerId: playerId,
        ids: [],
        games: games,
        selectedSeason: seasonId,
        selectedPosition: selectedPosition_stats || '',
        selectedMetricFilters,
        abortSignal,
      });
      if (response) {
        dispatch(setMultitoolEvents(response.data.events));
        dispatch(setFromFieldEventsDetails(response.data.fromEvents));
        dispatch(setToFieldEventsDetails(response.data.toEvents));
        dispatch(setMultitoolEventsLoading(false));
      }
    } catch (e) {
      // dispatch(setMultitoolEventsLoading(false));
    }
  };
