import { combineReducers } from 'redux';

import authReducer from 'reducers/auth.reducer';
import calendarReducer from 'reducers/calendar.reducer';
import clubsReducer from 'reducers/clubs.reducer';
import { downloadsReducer } from 'reducers/downloads.reducer';
import equipmentReducer from 'reducers/equipment.reducer';
import filtersReducer from 'reducers/filters.reducer';
import { hotkeysReducer } from 'reducers/hotkeys.reducer';
import interfaceReducer from 'reducers/interface.reducer';
import { metricsReducer } from 'reducers/metrics.reducer';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playerReducer, playerReducerV2 } from 'reducers/player.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { protocolReducer } from 'reducers/protocol.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';

const mainReducer = combineReducers({
  calendarReducer: calendarReducer,
  authReducer: authReducer,
  clubsReducer: clubsReducer,
  equipmentReducer: equipmentReducer,
  interfaceReducer: interfaceReducer,
  playerReducer: playerReducer,
  filtersReducer: filtersReducer,
  teamReducer: teamReducer.reducer,
  playlistReducerV2: playlistReducerV2.reducer,
  videosListReducer: videosListReducer.reducer,
  notificationsReducer: notificationsReducer.reducer,
  hotkeysReducer: hotkeysReducer.reducer,
  reelsReducer: reelsReducer.reducer,
  playerReducerV2: playerReducerV2.reducer,
  dowloadsReducer: downloadsReducer.reducer,
  protocolReducer: protocolReducer.reducer,
  teamPlayerReducer: teamPlayerReducer.reducer,
  metricsReducer: metricsReducer.reducer,
  teamPlayerStatsReducer: teamPlayerStatsReducer.reducer,
  teamPlayerStatsSectionReducer: teamPlayerStatsSectionReducer.reducer,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;
export default mainReducer;
