import * as React from 'react';
import { FC, forwardRef, useEffect, useRef, useState } from 'react';

import './index.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Collapse, Flex, Tooltip, Typography } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Checkbox, ConfigProvider, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AC_AddEventsToExpandedVideo,
  AC_AddUserEventsToExpandedVideo,
  AC_ToggleIsCollapsed,
} from 'actions/player.acitons';
import { EventsAPI } from 'api/events';
import { ReactComponent as ChevronRight } from 'assets/img/icons/faChevronRight.svg';
import { ReactComponent as FieldBlueIcon } from 'assets/img/icons/faFieldBlueIcon.svg';
import { AppStateType } from 'reducers';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';
import { useAppDispatch } from 'store';
import { formatDatetimeToDate } from 'types/functions';
import { GameVideoEpisodeType, PlaylistElementType } from 'types/types';
import { formatScore } from 'utils/functions';

import VideoListElement from '../VideoListElement';

const { Text } = Typography;

const GameVideosContainer: FC<any> = forwardRef(
  (
    {
      videoElement,
      episodesController,
      elementIndex,
      type = 'games',
      isShared, // Просматриваем расшаренный плейлист
      loadedEpisodesPagesController,
    },
    ref,
  ) => {
    const [filteredEpisodes, setFilteredEpisodes] = useState<Array<any>>(
      videoElement?.episodes || [],
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [userFilteredEpisodes, setUserFilteredEpisodes] = useState<
      Array<any>
    >([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [playlistEditInputError, setPlaylistEditInputError] = useState(false);
    const dispatch = useAppDispatch();
    const [t] = useTranslation();
    useEffect(() => {
      toggleSelectAll(true);
    }, []);
    useEffect(() => {
      setFilteredEpisodes(videoElement?.episodes);
      if (videoElement.user_episodes) {
        setUserFilteredEpisodes(videoElement.user_episodes);
      }
    }, [videoElement]);
    useEffect(() => {
      if (videoElement.isExpanded && filteredEpisodes.length === 0) {
        const controller: AbortController = new AbortController();
        getEvents(controller.signal).then();
        return () => {
          controller.abort('Operation canceled by the user.');
        };
      }
    }, [videoElement.isExpanded]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { videosListIsLoading } = useSelector(
      (state: AppStateType) => state.playerReducerV2,
    );
    const { AC_selectEpisode, AC_deselectEpisode } = videosListReducer.actions;
    const refAll = useRef<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeId, setActiveId] = useState(null);
    const getEvents = async (abortSignal: any) => {
      if (type === 'games') {
        let baseVideo =
          videoElement.activity_videos.length > 0
            ? videoElement.activity_videos[0]
            : null;
        //@ts-ignore
        if (videoElement.events?.length > 0) {
          setSections([]);
          setFilteredEpisodes([]);
          setUserFilteredEpisodes([]);
          dispatch(AC_AddEventsToExpandedVideo([], videoElement.id));
          setIsLoading(true);
          const events = await EventsAPI.getEpisodesByIds(
            videoElement.events,
            loadedEpisodesPagesController?.loadedPages[videoElement.id],
            abortSignal,
          );
          if (events.data && events.data.results) {
            setFilteredEpisodes(events.data.results);
            if (events.data.results.length > 0) {
              baseVideo = events.data.results[0].video;
            }

            dispatch(
              AC_AddEventsToExpandedVideo(events.data.results, videoElement.id),
            );
            if (!refAll?.current?.indeterminate && refAll?.current?.checked) {
              const updated = { ...episodesController.selectedEpisodes };
              updated[videoElement.id] = events.data.results.map(
                (el: any) => el.id,
              );
              episodesController.setSelectedEpisodes(updated);
            }
          }
          if (videoElement.user_events.length > 0) {
            const user_events = await EventsAPI.getUserEpisodesByIds(
              videoElement.user_events,
              loadedEpisodesPagesController?.loadedPages[videoElement.id],
            );
            if (user_events.data && user_events.data.results) {
              setUserFilteredEpisodes(user_events.data.results);
              if (events.data.results.length > 0) {
                baseVideo = user_events.data.results[0]?.video;
              }

              dispatch(
                AC_AddUserEventsToExpandedVideo(
                  user_events.data.results,
                  videoElement.id,
                ),
              );
              if (!refAll?.current?.indeterminate && refAll?.current?.checked) {
                const updated = { ...episodesController.selectedEpisodes };
                updated[videoElement.id] = user_events.data.results.map(
                  (el: any) => el.id,
                );
                // console.log('1');
                // console.log(updated);
                episodesController.setSelectedEpisodes(updated);
              }
            }
          }
        }
        let sectionEvents = [];
        if (
          Object.keys(videoElement).includes('game_events') &&
          videoElement.game_events.length > 0
        ) {
          setIsLoading(true);
          //@ts-ignore
          const response = await EventsAPI.getEpisodesByIds(
            videoElement?.game_events,
            1,
            abortSignal,
          );
          sectionEvents = response.data.results;
        }
        let userEvents = [];
        if (
          Object.keys(videoElement).includes('user_events') &&
          videoElement.user_events.length > 0
        ) {
          setIsLoading(true);
          //@ts-ignore
          const response = await EventsAPI.getUserEpisodesByIds(
            videoElement?.user_events,
            1,
            abortSignal,
          );
          userEvents = response.data.results;
          setUserFilteredEpisodes(userEvents);
          dispatch(
            AC_AddUserEventsToExpandedVideo(userEvents, videoElement.id),
          );
        }
        let results: any = [];
        if (baseVideo) {
          const mainSection = {
            file_timer: {
              started_at: 0,
              finished_at: Number(baseVideo.meta.video_length * 1000),
            },
            id: baseVideo?.id,
            attributes: [{ activity_type: 'game', name: 'Матч полностью' }],
            video: baseVideo,
          };
          results = [mainSection];
        }
        const s = [...results, ...sectionEvents];
        setSections(s);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        setFilteredEpisodes(videoElement.episodes);
        setIsLoading(false);
      }
    };

    const getGameName = () => {
      return `${videoElement.home_team?.name || t('Unknown team')} ${formatScore(videoElement.result?.score) || ''} ${videoElement.away_team?.name || t('Unknown team')}`;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [editPlaylist, setEditPlaylist] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sections, setSections] = useState<Array<GameVideoEpisodeType>>([]);
    const collapseList = () => {
      setEditPlaylist(false);
      if (type === 'games') {
        dispatch(AC_ToggleIsCollapsed(videoElement.id));
      } else {
        dispatch(AC_toggleCollapsedPlaylistGame(elementIndex));
      }
      setCurrentScrollTop(0);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const playlistNameEditInputRef = useRef<any>(null);

    const toggleEpisodeSelection = (
      isChecked: boolean,
      episodeId: string,
      gameId: string,
      totalEpisodes?: number,
    ) => {
      const updated = { ...episodesController.selectedEpisodes };
      // const episodeId = type === "games" ? episode.id : episode.event_id;
      if (isChecked) {
        dispatch(
          AC_selectEpisode({
            gameId,
            episodeId,
            totalGameLength: totalEpisodes,
          }),
        );
        if (
          Object.keys(updated).includes(`${videoElement.id}-${elementIndex}`)
        ) {
          updated[`${videoElement.id}-${elementIndex}`] = [
            ...updated[`${videoElement.id}-${elementIndex}`],
            episodeId,
          ];
        } else {
          updated[`${videoElement.id}-${elementIndex}`] = [episodeId];
        }
      } else {
        dispatch(
          AC_deselectEpisode({
            gameId,
            episodeId,
            totalGameLength: totalEpisodes,
          }),
        );
        if (
          Object.keys(updated).includes(`${videoElement.id}-${elementIndex}`)
        ) {
          updated[`${videoElement.id}-${elementIndex}`] = updated[
            `${videoElement.id}-${elementIndex}`
          ]?.filter((el: string) => el !== episodeId);
          if (updated[`${videoElement.id}-${elementIndex}`].length === 0) {
            delete updated[`${videoElement.id}-${elementIndex}`];
          }
        }
      }
      episodesController.setSelectedEpisodes(updated);
    };
    const { playerMode } = useSelector(
      (state: AppStateType) => state.playerReducer,
    );
    const { selected } = useSelector(
      (state: AppStateType) => state.videosListReducer,
    );
    const { AC_toggleCollapsedPlaylistGame } = playlistReducerV2.actions;
    const { AC_selectGame, AC_deselectGame } = videosListReducer.actions;

    const toggleSelectAll = (selectAll = false) => {
      if (playerMode === 'episodes') {
        if (
          !selectAll &&
          selected[`${videoElement.id}-${elementIndex}`]?.allSelected
        ) {
          dispatch(AC_deselectGame(`${videoElement.id}-${elementIndex}`));
        } else {
          dispatch(
            AC_selectGame({
              gameId: `${videoElement.id}-${elementIndex}`,
              episodes: videoElement.episodes.map((ep: any) => ep.id),
            }),
          );
        }
      } else {
        if (!selectAll && selected[`${videoElement.id}`]?.allSelected) {
          dispatch(AC_deselectGame(`${videoElement.id}`));
        } else {
          dispatch(
            AC_selectGame({
              gameId: `${videoElement.id}`,
              episodes: videoElement.episodes.map((ep: any) => ep.id),
            }),
          );
        }
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentScrollTop, setCurrentScrollTop] = useState<number>(0);
    const [isChecked, setIsChecked] = useState(false);
    const [isIndeterminate, setIsIndeterminate] = useState(false);
    useEffect(() => {
      if (selected) {
        if (playerMode === 'episodes') {
          setIsChecked(
            selected[`${videoElement.id}-${elementIndex}`]?.allSelected,
          );
          setIsIndeterminate(
            !selected[`${videoElement.id}-${elementIndex}`]?.allSelected &&
              selected[`${videoElement.id}-${elementIndex}`]?.episodes.length >
                0,
          );
        } else {
          setIsChecked(selected[`${videoElement.id}`]?.allSelected);
          setIsIndeterminate(
            !selected[`${videoElement.id}`]?.allSelected &&
              selected[`${videoElement.id}`]?.episodes.length > 0,
          );
        }
      }
    }, [selected]);
    return (
      <>
        {/* <Tooltip
          id={'longPlaylistName'}
          arrow={true}
          className={'videoAttributesTooltip'}
        /> */}
        <ConfigProvider
          theme={{
            token: {
              fontSize: 12,
            },
            components: {
              Collapse: {
                headerPadding: '8px 12px 8px 12px',
                contentPadding: 0,
                contentBg: 'rgba(255, 255, 255, 0.08)',
                colorBorder: 'transparent',
                borderRadiusLG: 8,
              },
            },
          }}
        >
          <Collapse
            expandIcon={({ isActive }) => (
              <ChevronRight
                style={{
                  transform: `${!isActive ? '' : 'rotate(90deg)'}`,
                }}
              />
            )}
            onChange={collapseList}
            defaultActiveKey={[1]}
            className={`gameVideoContainer-v2 flex-row enabledClickable ${videoElement.isExpanded ? '' : 'gameVideoContainer-v2-collapsed'}`}
            items={[
              {
                key: 1,
                className: 'collapse-header-class',
                label: (
                  <div className="gameBlock">
                    <div className={'enabledClickable gameBlockTitle'}>
                      <Flex justify="space-between">
                        <Text
                          className="elipsis-text color-description roboto-uppercase"
                          ellipsis={{
                            tooltip: videoElement?.name?.length,
                          }}
                        >
                          {getGameName()}
                        </Text>
                        <Flex gap={16} align="center">
                          <Flex
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            className="activeButtonIcon"
                            style={{
                              width: 20,
                              height: 20,
                              padding: '10px 0px',
                            }}
                            align="center"
                            justify="center"
                          >
                            <FieldBlueIcon />
                          </Flex>
                          {!isShared && (
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorBgContainer: '#141414',
                                  colorBorder: '#424242',
                                  lineWidth: 1,
                                },
                              }}
                            >
                              <Checkbox
                                checked={isChecked}
                                indeterminate={isIndeterminate}
                                // ref={refAll}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSelectAll(false);
                                }}
                              />
                            </ConfigProvider>
                          )}
                        </Flex>
                      </Flex>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        alignSelf: 'stretch',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div>{formatDatetimeToDate(videoElement.started_at)}</div>
                      <div className="filtersPanelTopBar_content_matchLine_divider"></div>
                      <Text
                        className="elipsis-text color-description"
                        ellipsis={{
                          tooltip: videoElement.tournament?.country?.name,
                        }}
                      >
                        {videoElement.tournament?.country?.name}
                      </Text>
                      <div className="filtersPanelTopBar_content_matchLine_divider"></div>
                      <Text
                        className="elipsis-text color-description"
                        ellipsis={{
                          tooltip: videoElement.tournament?.name,
                        }}
                      >
                        {videoElement.tournament?.name}
                      </Text>
                    </div>
                  </div>
                ),
                children: [
                  filteredEpisodes.map(
                    (episode: GameVideoEpisodeType | PlaylistElementType) => {
                      return (
                        <VideoListElement
                          key={`id-${episode.id}`}
                          id={episode.id}
                          activityId={videoElement.id}
                          activeId={activeId}
                          episode={episode}
                          selectedEpisodes={
                            episodesController.selectedEpisodes[
                              `${videoElement.id}-${elementIndex}`
                            ]
                          }
                          toggleEpisodeSelection={toggleEpisodeSelection}
                          isShared={isShared}
                          ref={ref}
                          type={type}
                          elementIndex={elementIndex}
                          videoElement={videoElement}
                        />
                      );
                    },
                  ),
                  userFilteredEpisodes.length > 0 && (
                    <ConfigProvider
                      theme={{
                        components: {
                          Divider: {
                            margin: 0,
                            colorBorder: 'red',
                          },
                        },
                        token: {
                          // colorText: "var(--colorTextDisabled, rgba(255, 255, 255, 0.25)",
                          // colorSplit: "var(--colorTextDisabled, rgba(255, 255, 255, 0.25)",
                          // colorSplit: "rgba(255, 255, 255, 0.25)",
                        },
                      }}
                    >
                      <Divider
                        orientation="left"
                        plain
                        className="my-episodes-divider"
                        style={{
                          color: 'var(--colorTextDisabled)',
                          padding: '0px 12px',
                          borderBlockStart:
                            'var(--colorFillTertiary, rgba(255, 255, 255, 0.08))',
                        }}
                      >
                        {t('My episodes')}
                      </Divider>
                    </ConfigProvider>
                  ),
                  userFilteredEpisodes.map(
                    (episode: GameVideoEpisodeType | PlaylistElementType) => {
                      return (
                        <VideoListElement
                          key={`id-${episode.id}`}
                          id={episode.id}
                          activeId={activeId}
                          activityId={videoElement.id}
                          episode={episode}
                          selectedEpisodes={
                            episodesController.selectedEpisodes[
                              `${videoElement.id}-${elementIndex}`
                            ]
                          }
                          toggleEpisodeSelection={toggleEpisodeSelection}
                          isShared={isShared}
                          ref={ref}
                          type={type}
                          elementIndex={elementIndex}
                          videoElement={videoElement}
                        />
                      );
                    },
                  ),
                ],
              },
            ]}
          />
        </ConfigProvider>
      </>
    );
  },
);
export default GameVideosContainer;
